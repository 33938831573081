import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import LinearProgress from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useCallback, useEffect, useState } from "react";
import { i18n, i18nSheet } from "../../../dataSheets/generated/i18n";
import { GetText } from "../../../dataSheets/i18n";
import { ColumnEditor, DataViewColumn, DataViewEditorControl, EnumValueType } from "../../../model/DataView";

type SelectColumnViewerOptions = {
    I18n?: boolean;
    HideInEditMode?: boolean;
    HideInViewMode?: boolean;
    HideInAddMode?: boolean;
}

export default class SelectColumnViewer implements ColumnEditor {
    constructor(private label: i18n, private enumValues: EnumValueType[] | (() => Promise<EnumValueType[]>), private options?: SelectColumnViewerOptions) {

    }

    RenderView(data: any, col: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) {
        if (this.options?.HideInViewMode) {
            return null;
        }
        return <EnumViewer label={this.label} enumValues={this.enumValues} options={this.options} controls={controls} col={col} forceUpdate={forceUpdate} />
    }

    RenderAdd(col: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) {
        if (this.options?.HideInAddMode) {
            return null;
        }
        return this.renderEdit(col, controls, forceUpdate);
    }

    RenderEdit(col: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) {
        if (this.options?.HideInEditMode) {
            return null;
        }
        return this.renderEdit(col, controls, forceUpdate);
    }

    renderEdit(col: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) {
        return <EnumViewer label={this.label} enumValues={this.enumValues} options={this.options} controls={controls} col={col} isEdit forceUpdate={forceUpdate} />
    }
}

type EnumViewerProps = {
    label: i18n;
    enumValues: EnumValueType[] | (() => Promise<EnumValueType[]>);
    options?: SelectColumnViewerOptions;
    controls: DataViewEditorControl;
    col: DataViewColumn;
    isEdit?: boolean;
    forceUpdate: any;
}

function EnumViewer(props: EnumViewerProps) {
    const [enums, setEnums] = useState<EnumValueType[]>([]);
    const { enumValues, options, label, controls, col } = props;
    const [value, setValue] = useState('');
    const data = controls.GetEditValue(col.Field);
    const [loading, setLoading] = useState(enumValues instanceof Function);

    const getDisplay = useCallback((enumValues: EnumValueType[]) => {
        return enumValues.find(([v, _]) => v === data)?.[1] ?? '';
    }, [])

    useEffect(() => {
        (async () => {
            if (enumValues instanceof Function) {
                setLoading(true);
                const res = await enumValues()
                setEnums(res);
                setValue(getDisplay(res));
                setLoading(false);
            } else {
                setEnums(enumValues);
                setValue(getDisplay(enumValues));
                setLoading(false);
            }
        })()
    }, [col.Field, enumValues, getDisplay, props.forceUpdate])

    if (props.isEdit) {
        const error = controls.GetError(col.Field);
        return <Stack key={col.Name.Id} sx={{ position: 'relative' }}>
            {loading && <LinearProgress sx={{ position: 'absolute', width: '100%', bottom: 0 }} />}
            <FormControl error={error !== undefined} disabled={loading} size="small" variant="filled" sx={{ minWidth: 150 }}>
                <InputLabel>{GetText(label)}</InputLabel>
                <Select label={GetText(label)} value={controls.GetEditValue(col.Field) ?? ""} onChange={(e) => { controls.SetNewData(col.Field, e.target.value) }}>
                    <MenuItem value="">{GetText(i18nSheet.DataView_None)}</MenuItem>
                    {enums.map(([value, display]) => <MenuItem key={value} value={value}>{options?.I18n ? GetText(display) : display}</MenuItem>)}
                </Select>
                <FormHelperText>{error ? GetText(error.Error) : undefined}</FormHelperText>
            </FormControl >
        </Stack>
    }
    return <Stack key={col.Name.Id} sx={{ position: 'relative' }}>
        {loading && <LinearProgress sx={{ position: 'absolute', width: '100%', bottom: 0 }} />}
        <TextField variant="filled" label={GetText(label)} value={options?.I18n ? GetText(value) : value} />
    </Stack>
}