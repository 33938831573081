import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useEffect, useState } from "react";
import { i18nSheet } from "../../../dataSheets/generated/i18n";
import { GetText } from "../../../dataSheets/i18n";
import { Query, QueryDropRendererProps, QueryProps } from "./QueryBox";

export type EnumValueType = [value: string | number, display: string];

export type EnumQueryProps = QueryProps & {
    EnumValues: EnumValueType[] | (() => Promise<EnumValueType[]>);
    I18n?: boolean;
}

export default class EnumQuery extends Query {
    EnumValues: EnumValueType[] | (() => Promise<EnumValueType[]>);
    I18n: boolean;

    constructor(props: EnumQueryProps) {
        super(props);
        this.EnumValues = props.EnumValues;
        this.I18n = !!props.I18n;
    }

    DropRenderer: (props: QueryDropRendererProps) => JSX.Element = (props) => {
        const [isLoading, setIsLoading] = useState<boolean>(true);
        const [enums, setEnums] = useState<EnumValueType[]>([]);
        const [dropSelectedIndex, setDropSelectedIndex] = useState<number>(-1);
        const [filter, setFilter] = useState<string>('');

        const filteredEnums = !filter ? enums : enums.filter((enumValue) => {
            const text = (this.I18n ? GetText(enumValue[1]) : enumValue[1]).toLowerCase();
            if (text.includes(filter)) {
                return true;
            }
            return false;
        });

        props.DropEvents.OnKeyDown = (e) => {
            if (isLoading) {
                e.preventDefault();
                return;
            }
            if (e.key === 'ArrowUp') {
                setDropSelectedIndex((prev) => {
                    if (prev === -1) {
                        return filteredEnums.length - 1;
                    } else {
                        return prev - 1;
                    }
                });
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                setDropSelectedIndex((prev) => {
                    if (prev === filteredEnums.length - 1) {
                        return -1;
                    } else {
                        return prev + 1;
                    }
                });
                e.preventDefault();
            } else if (e.key === 'Enter') {
                if (dropSelectedIndex !== -1 && dropSelectedIndex < filteredEnums.length) {
                    setQueryValue(filteredEnums[dropSelectedIndex]);
                }
                e.currentTarget.value = '';
                e.preventDefault();
            }
        }

        props.DropEvents.OnInputChange = (e) => {
            setFilter(e.target.value.toLowerCase());
            if (e.currentTarget.value === '') {
                setDropSelectedIndex(-1);
            } else {
                setDropSelectedIndex(0);
            }
        }

        useEffect(() => {
            (async () => {
                if (this.EnumValues instanceof Function) {
                    const res = await this.EnumValues()
                    setEnums(res);
                    setIsLoading(false);
                } else {
                    setEnums(this.EnumValues);
                    setIsLoading(false);
                }
            })()
        }, []);

        const setQueryValue = (enumValue: EnumValueType) => {
            props.SetQueryValue(enumValue[0].toString(), () => { return this.I18n ? GetText(enumValue[1]) : enumValue[1] });
        }

        const noOption = filteredEnums.length === 0 && !isLoading;

        return <Box sx={{
            padding: 1,
        }}>
            <LinearProgress sx={{ display: isLoading ? 'block' : 'none', width: '10rem' }} />
            {noOption ? <Box
                sx={{
                    padding: '0.5rem 1rem',
                    color: (theme) => theme.palette.text.disabled,
                }}
            >
                {GetText(i18nSheet.DataView_NoOption)}
            </Box> : filteredEnums.map(([value, display], index) => {
                const selected = index === dropSelectedIndex;
                return <Box key={value} sx={{
                    padding: '0.5rem 1rem',
                    cursor: 'pointer',
                    borderRadius: 2,
                    ':hover': {
                        backgroundColor: (theme) => theme.palette.action.hover
                    },
                    border: selected ? '1px solid rgba(0, 119, 255, 1)' : '1px solid transparent',
                }} onMouseDown={() => {
                    setQueryValue([value, display]);
                }}>{this.I18n ? GetText(display) : display}</Box>
            })}
        </Box>
    }

    async GetDisplay(value: string) {
        if (typeof this.EnumValues === 'function') {
            const res = await this.EnumValues();
            const enumValue = res.find((x) => x[0].toString() === value);
            if (enumValue) {
                return () => { return this.I18n ? GetText(enumValue[1]) : enumValue[1] };
            }
            return null;
        } else {
            const enumValue = this.EnumValues.find((x) => x[0].toString() === value);
            if (enumValue) {
                return () => { return this.I18n ? GetText(enumValue[1]) : enumValue[1] };
            }
            return null;
        }
    }

    OnInputBlur(value: string) {
        return null;
    }


}