import { Route, Routes } from "react-router-dom";
import { User } from "../network/Account";
import Account from "./backStageSettings/Account";
import Audit from "./backStageSettings/Audit";
import Operator from "./backStageSettings/Operator";
import Role from "./backStageSettings/Role";
import Site from "./backStageSettings/Site";
import BetRecord from "./gameSettings/BetRecord";
import GameMgmt from "./gameSettings/GameMgmt";
import IncomeReport from "./gameSettings/IncomeReport";
import RiskControl from "./gameSettings/RiskControl";
import NotFoundPage from "./NotFound";
import TestView from "./TestView";
import UserPage from "./UserPage";

export function MainContent() {
    return <Routes>
        {User.value?.Permissions.includes('ViewGameManagement') && <Route path="/game_settings/game_management" element={<GameMgmt />} />}
        {User.value?.Permissions.includes('ViewRiskControl') && User.value?.IsSiteOperator && <Route path="/game_settings/risk_control" element={<RiskControl />} />}
        {User.value?.Permissions.includes('ViewBetRecord') && <Route path="/game_settings/bet_record" element={<BetRecord />} />}
        {User.value?.Permissions.includes('ViewIncomeReport') && <Route path="/game_settings/income_report" element={<IncomeReport />} />}

        {User.value?.Permissions.includes('ViewSiteAccount') && <Route path="/settings/account" element={<Account />} />}
        {User.value?.Permissions.includes('ViewSiteRole') && <Route path="/settings/role" element={<Role />} />}
        {User.value?.Permissions.includes('ViewSiteOperator') && <Route path="/settings/operator" element={<Operator />} />}
        {User.value?.Permissions.includes('EditSiteSetting') && <Route path="/settings/site" element={<Site />} />}
        {User.value?.Permissions.includes('ViewAuditLog') && <Route path="/settings/audit_log" element={<Audit />} />}

        <Route path="/test" element={<TestView />} />
        <Route path="/" />
        <Route path="/user" element={<UserPage />} />
        <Route path="/*" element={<NotFoundPage />} />
    </Routes>
}