import Switch from "@mui/material/Switch";
import { DataViewColumn, DataViewRowControl } from "../../../model/DataView";

type SwitchTableRendererProps = {
    controls: DataViewRowControl;
    editable?: boolean;
    data: any,
    col: DataViewColumn
}

export function SwitchTableRenderer(props: SwitchTableRendererProps) {
    const editingValue = props.controls.GetNewValue(props.data, props.col.Field);
    const checked = editingValue ?? (props.data[props.col.Field] === true || props.data[props.col.Field] === 1);

    const error = props.data[props.col.Field] === undefined;
    const handleChange = () => {
        props.controls.SetNewValue(props.data, props.col.Field, !checked);
    }

    if (error) {
        return <Switch disabled sx={{ opacity: 0.5, '&.MuiSwitch-root .MuiSwitch-switchBase': { color: theme => theme.palette.error.dark }, '&.MuiSwitch-root .MuiSwitch-track': { bgcolor: theme => theme.palette.error.dark } }} />
    } else if (props.editable && props.controls.HasEditPermission) {
        return <Switch checked={checked} onChange={handleChange} />
    } else {
        return <Switch checked={checked} />
    }
} 