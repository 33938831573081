import { createTheme } from '@mui/material/styles';

export const DarkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#64b5f6',
            light: '#90caf9',
            dark: '#1976d2',
        },
        secondary: {
            main: '#4dd0e1',
            light: '#b2ebf2',
            dark: '#00838f',
        },
        background: {
            default: '#010104',
            paper: '#07111a',
        },
        text: {
            primary: '#f3e1e1',
        },
        error: {
            main: '#ff1744',
            light: '#ff5252',
            dark: '#d50000',
        },
    },
});