import { i18n } from "../dataSheets/generated/i18n";

export type EnumValueType = [value: string | number, display: string];

export type DataViewColumn = {
    Name: i18n;
    Field: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    TableRenderer?: (data: any, column: DataViewColumn, control: DataViewControl) => JSX.Element;
    HeaderRenderer?: (column: DataViewColumn, control: DataViewControl) => JSX.Element;
    ViewRenderer?: ColumnEditor;
}

export type Page = {
    Page: string;
    PageSize: string;
}

export type DataViewControl = {
    Editor: DataViewEditorControl;
    Row: DataViewRowControl;
    Table: DataViewTableControl;
}

export type DataViewEditorControl = {
    CloseEdit: (refreshData?: boolean) => void;
    OpenEdit: (data: any) => void;
    OpenView: (data: any) => void;
    OpenDelete: (data: any) => void;
    OpenAdd: () => void;
    OpenCustom: (data: any, renderer: CustomViewRenderer, size?: DataViewEditorSize) => void;
    SetNewData: (key: string, value: any) => void;
    GetEditValue: (key: string) => any;
    SetError: (key: string, error: string, errorParams: any) => void;
    GetError: (key: string) => { Error: string, ErrorParams: any } | undefined;
}

export type DataViewRowControl = {
    GetNewValue: (row: any, col: string) => any
    SetNewValue: (row: any, col: string, value: any) => void;
    HasNewData: (row: any) => boolean;
    Save: (row: any) => Promise<void>;
    readonly IsLoading: boolean;
    readonly HasEditPermission: boolean;
}

export type DataViewTableControl = {
    Data: any[] | undefined;
    SetData: React.Dispatch<React.SetStateAction<any[] | undefined>>;
}

export type QueryFunctionType = (queries: { [key: string]: string }, page: Page) => Promise<unknown>;

type CustomViewRenderer = (data: any, controls: DataViewEditorControl, forceUpdate: any) => JSX.Element;

export enum DataViewAction {
    Edit,
    View,
    Add,
    Delete,
    Custom
}

export enum DataViewEditorSize {
    Small,
    Normal
}

export type DataViewEditProps = ({
    Action: DataViewAction.View,
    Data: any
} | {
    Action: DataViewAction.Edit,
    Data: any
} | {
    Action: DataViewAction.Add
} | {
    Action: DataViewAction.Delete,
    Data: any
} | {
    Action: DataViewAction.Custom,
    Data: any,
    Renderer: CustomViewRenderer,
    Size: DataViewEditorSize
}) & {
    ForceUpdate: any // React 用於重新觸發 useEffect
}

export interface ColumnEditor {
    RenderView?: (data: any, column: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) => JSX.Element | null;
    RenderAdd?: (column: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) => JSX.Element | null;
    RenderEdit?: (column: DataViewColumn, controls: DataViewEditorControl, forceUpdate: any) => JSX.Element | null;
    Equals?(data1: any, data2: any): boolean;
}