import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { useSignals } from '@preact/signals-react/runtime';
import DataView from "../../components/DataView/DataView";
import EnumQuery from '../../components/DataView/Query/EnumQuery';
import StringQuery from '../../components/DataView/Query/StringQuery';
import { NumberTableRenderer } from '../../components/DataView/TableRenderer/NumberTableRenderer';
import { SwitchTableRenderer } from "../../components/DataView/TableRenderer/SwitchTableRenderer";
import { SaveButton } from "../../components/IconButton";
import { SnackError } from '../../components/Snackbar';
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { DataViewColumn, DataViewRowControl } from "../../model/DataView";
import { User } from "../../network/Account";
import { GetOperators } from "../../network/Operator";
import { Get, Post } from "../../Utils/net";

const EditPermission = 'EditRiskControl';

export default function RiskControl() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.Game_Name,
            Field: 'Name',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.Account_Operator,
            Field: 'Operator',
            TableRenderer: User?.value?.IsSiteOperator ? (data, column) => <>{data[column.Field]}</> : undefined
        },
        {
            Name: i18nSheet.RiskControl_ForcedOn,
            Field: 'ForcedOn',
            TableRenderer: (data, column, control) => <><SwitchTableRenderer data={data} col={column} controls={control.Row} editable={User.value?.Permissions.includes(EditPermission)} /></>
        },
        {
            Name: i18nSheet.RiskControl_RTPThreshold,
            Field: '',
            TableRenderer: (data, column, control) => <RTPSlider data={data} col={column} controls={control.Row} editable={User.value?.Permissions.includes(EditPermission)} />
        },
        {
            Name: i18nSheet.RiskControl_CurrentRTP,
            Field: 'CurrentRTP',
            TableRenderer: (data, column) => <>{data[column.Field] ?? '-'}</>
        },
        {
            Name: i18nSheet.RiskControl_ReRoll,
            Field: 'EnableReRoll',
            TableRenderer: (data, column, control) => <><SwitchTableRenderer data={data} col={column} controls={control.Row} editable={User.value?.Permissions.includes(EditPermission)} /></>
        },
        {
            Name: i18nSheet.RiskControl_ReRollRTP,
            Field: 'ReRollThreshold',
            TableRenderer: (data, column, control) => <NumberTableRenderer sx={{ width: 60 }} data={data} col={column} controls={control.Row} editable={User.value?.Permissions.includes(EditPermission)} inputProps={{ min: 0.0, max: 0.99, step: 0.01 }} />
        },
        {
            Name: i18nSheet.DataView_Action,
            Field: '',
            TableRenderer: User.value?.Permissions.includes(EditPermission) ? (data, col, control) => {
                return <>
                    <SaveButton disabled={!control.Row.HasNewData(data)} row={data} controls={control.Row} />
                </>
            } : undefined
        }
    ]

    return <DataView
        Queries={[
            new EnumQuery({ Label: i18nSheet.Account_Operator, Field: 'OperatorId', EnumValues: GetOperators, Disabled: !User.value?.IsSiteOperator }),
            new StringQuery({ Label: i18nSheet.Game_Name, Field: 'Name' }),
        ]}
        QueryFunction={async (queries, page) => {
            const req: Record<string, string> = { ...page }
            if (!queries.OperatorId) {
                SnackError(i18nSheet.Error_NoOperatorSelect);
                return [];
            }
            if (queries.Name) {
                req.Name = queries.Name
            }
            req.OperatorId = queries.OperatorId;
            return await Get('/api/riskControl/games', req)
        }}
        EditPermission={EditPermission}
        Columns={columns}
        InTableEdit
        DataIdField="Id"
        ModifyData={async (row, newData) => {
            let operatorId: number | undefined;
            if (User.value?.IsSiteOperator) {
                operatorId = row.OperatorId;
            }
            const res = await Post('/api/riskControl/modifyGame', {
                Id: row.Id,
                OperatorId: operatorId,
                ForcedOn: newData.ForcedOn,
                OnThreshold: newData.OnThreshold ?? row.OnThreshold,
                OffThreshold: newData.OffThreshold ?? row.OffThreshold,
                EnableReRoll: newData.EnableReRoll,
                ReRollThreshold: newData.ReRollThreshold ?? row.ReRollThreshold
            })
            if (res.Error) {
                SnackError(res.Error);
            }
            return res;
        }}
    ></DataView>
}

type RTPSliderProps = {
    data: any;
    col: DataViewColumn;
    editable?: boolean;
    controls: DataViewRowControl;
}

function RTPSlider(props: RTPSliderProps) {
    const onThreshold = props.controls.GetNewValue(props.data, 'OnThreshold') ?? props.data.OnThreshold;
    const offThreshold = props.controls.GetNewValue(props.data, 'OffThreshold') ?? props.data.OffThreshold;
    const value = [offThreshold, onThreshold, 1]

    const error = typeof onThreshold !== 'number' || typeof offThreshold !== 'number' || isNaN(onThreshold) || isNaN(offThreshold);

    const handleChange = (event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            return;
        }
        props.controls.SetNewValue(props.data, 'OnThreshold', newValue[1]);
        props.controls.SetNewValue(props.data, 'OffThreshold', newValue[0]);
    }

    const handleOffChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue: number | undefined = parseFloat((event.target as HTMLInputElement).value);
        if (isNaN(newValue)) {
            newValue = props.data.OffThreshold;
        } else {
            if (newValue > onThreshold) {
                return;
            }
        }
        props.controls.SetNewValue(props.data, 'OffThreshold', newValue);
    }

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseFloat((event.target as HTMLInputElement).value);
        if (isNaN(newValue)) {
            newValue = props.data.onThreshold;
        } else {
            if (newValue < offThreshold) {
                return;
            }
        }
        props.controls.SetNewValue(props.data, 'OnThreshold', newValue);
    }



    if (error) {
        return <Box sx={{ display: 'flex', gap: 2 }}>
            <Input sx={{ width: 120 }} size="small" value="" disabled onChange={handleOffChange} inputProps={{ type: 'number' }} />
            <Slider size="small" value={0} disabled />
            <Input sx={{ width: 120 }} size="small" value="" disabled inputProps={{ type: 'number' }} />
        </Box>
    }

    return <Box sx={{ display: 'flex', gap: 2 }}>
        <Input sx={{ width: 120 }} size="small" disabled={!props.editable} value={offThreshold} onChange={handleOffChange} inputProps={{ step: 0.01, type: 'number', min: 0.5, max: onThreshold }} />
        <Slider sx={{ '& .MuiSlider-thumb[data-index="2"]': { display: 'none' } }} size="small" disabled={!props.editable} value={value} min={0.5} max={0.98} step={0.01} valueLabelDisplay='auto' onChange={handleChange} />
        <Input sx={{ width: 120 }} size="small" disabled={!props.editable} value={onThreshold} onChange={handleOnChange} inputProps={{ step: 0.01, type: 'number', min: offThreshold, max: 0.98 }} />
    </Box>
}