import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import DateTimeQuery, { DateTimeQueryType } from "../components/DataView/Query/DateTimeQuery";
import EnumQuery, { EnumValueType } from "../components/DataView/Query/EnumQuery";
import { QueryBox, QueryBoxProps } from "../components/DataView/Query/QueryBox";
import StringQuery from "../components/DataView/Query/StringQuery";
import { i18nSheet } from "../dataSheets/generated/i18n";
import { GetGameIds } from "../network/Game";
import { GetRoles } from "../network/Role";

const Status: EnumValueType[] = [
    ["Active", "Account_Active"],
    ["Freezed", "Account_Freezed"],
    ["Deleted", "Account_Deleted"],
]

export default function TestView() {
    const QueryProps: QueryBoxProps = {
        Queries: [
            new StringQuery({ Label: i18nSheet.Login_Account, Field: 'Name' }),
            new EnumQuery({
                Label: i18nSheet.Game_Name, Field: 'Game', EnumValues: async () => {
                    const res = await GetGameIds();
                    return res.map((x) => [x, x]);
                }
            }),
            new EnumQuery({ Label: i18nSheet.Account_Role, Field: 'Role', EnumValues: GetRoles }),
            new EnumQuery({ Label: i18nSheet.Account_Status, Field: 'Status', EnumValues: Status, I18n: true }),
            new DateTimeQuery({ Label: i18nSheet.DataView_StartTime, Field: 'StartTime', Type: DateTimeQueryType.Date }),
        ],
        OnFetch: async (queries) => {

        },
        Loading: false
    }
    const [loading, setLoading] = useState<boolean>(false);

    return <Box sx={{ padding: 2, position: 'relative', width: '100%', boxSizing: 'border-box', overflow: 'auto' }}>
        <Paper sx={{ padding: 2 }}>
            <QueryBox {...QueryProps} />
        </Paper>
    </Box>
}