//////////////////////////////////////////////////////////////////////
///                                                                ///
///                     Machine Generated Code                     ///
///                         Do Not Modify                          ///
///                                                                ///
//////////////////////////////////////////////////////////////////////


export class i18n{
    public readonly Id: string;
    public readonly en: string;
    public readonly zh_TW: string;

    constructor(Id: string, en: string, zh_TW: string){
        this.Id = Id;
        this.en = en;
        this.zh_TW = zh_TW;
    }
}

class DataSheet {
    public readonly DataArray: i18n[] = [
        new i18n("Account_Active", "Active", "啟用"),
        new i18n("Account_Deleted", "Deleted", "刪除"),
        new i18n("Account_Freezed", "Locked", "凍結"),
        new i18n("Account_LastLogin", "Last Login Time", "最後登入時間"),
        new i18n("Account_Operator", "Operator", "營運商"),
        new i18n("Account_Role", "Role", "角色"),
        new i18n("Account_Status", "Status", "狀態"),
        new i18n("Account_Unfreeze", "Unfreeze", "解凍"),
        new i18n("Audit_Action", "Action", "操作"),
        new i18n("Audit_AdminName", "Account", "帳號名稱"),
        new i18n("Audit_Content", "Content", "內容"),
        new i18n("Audit_Table", "Table", "表格"),
        new i18n("Audit_Time", "Time", "時間"),
        new i18n("BetRecord_BetAmount", "Bet Amount", "下注金額"),
        new i18n("BetRecord_BetTime", "Bet Time", "下注時間"),
        new i18n("BetRecord_Count", "Count", "次數"),
        new i18n("BetRecord_GameType", "Game Type", "遊戲種類"),
        new i18n("BetRecord_PlayerName", "Player Name", "玩家名稱"),
        new i18n("BetRecord_TransId", "Transaction ID", "交易ID"),
        new i18n("BetRecord_WinAmount", "Payout Amount", "中獎金額"),
        new i18n("DataView_Action", "Action", "操作"),
        new i18n("DataView_Add", "Add", "新增"),
        new i18n("DataView_AddWithTitle", "Add {0}", "新增{0}"),
        new i18n("DataView_CopyToClipboard", "Copy to clipboard", "複製"),
        new i18n("DataView_Delete", "Delete", "刪除"),
        new i18n("DataView_DeleteWithTitle", "Delete {0}", "刪除{0}"),
        new i18n("DataView_DeleteWithTitleAndName", "Delete {0}: {1}", "刪除{0}: {1}"),
        new i18n("DataView_Detail", "Detail", "詳細資訊"),
        new i18n("DataView_DetailWithTitle", "{0} Detail", "{0}詳細資訊"),
        new i18n("DataView_Edit", "Modify", "修改"),
        new i18n("DataView_EditWithTitle", "Modify {0}", "修改{0}"),
        new i18n("DataView_EndTime", "End Time", "結束時間"),
        new i18n("DataView_Filter", "Filter", "篩選器"),
        new i18n("DataView_MoreWithNumber", "... {0} more", "... 還有 {0} 項"),
        new i18n("DataView_NoDataFound", "No Data Found", "查無資料"),
        new i18n("DataView_NoOption", "No option", "沒有選項"),
        new i18n("DataView_None", "None", "無"),
        new i18n("DataView_Query", "Query", "查詢"),
        new i18n("DataView_Remark", "Remark", "備註"),
        new i18n("DataView_Save", "Save", "儲存"),
        new i18n("DataView_SaveAll", "Save all", "全部儲存"),
        new i18n("DataView_StartTime", "Start Time", "起始時間"),
        new i18n("Error_ArcadeFetchFailed", "Failed to fetch arcade server", "機台伺服器連線錯誤"),
        new i18n("Error_EmptyField", "Field requires value", "欄位必須有值"),
        new i18n("Error_FormatError", "Format error", "格式錯誤"),
        new i18n("Error_InvalidMaxLength", "String length must not exceed {0}", "字串長度須小於{0}"),
        new i18n("Error_InvalidMaxValue", "Must be less than or equal to {0}", "必須小於或等於 {0}"),
        new i18n("Error_InvalidMinLength", "String length must exceed {0}", "字串長度須長於{0}"),
        new i18n("Error_InvalidMinValue", "Must be greater than or equal to {0}", "必須大於或等於 {0}"),
        new i18n("Error_InvalidRequest", "Invalid request: {0}", "無效請求: {0}"),
        new i18n("Error_InvalidToken", "Invalid token", "驗證碼無效"),
        new i18n("Error_NoOperatorSelect", "No operator selected", "請選擇營運商"),
        new i18n("Error_OnRiskControllThreshold_3", "Re-roll RTP must greater or equal to on threshold", "重骰 RTP 必須大於 RTP 開啟閥值"),
        new i18n("Error_OperatorInUse", "Operator is in use", "營運商被使用中"),
        new i18n("Error_RoleInUse", "Role is in use", "角色被使用中"),
        new i18n("Error_ServerClosed", "Server closed", "伺服器關閉"),
        new i18n("Error_TypeError", "Type error", "型別錯誤"),
        new i18n("Error_Unauthorized", "Unauthorized", "未授權"),
        new i18n("Error_Unknown", "Unknown Error: {0}", "未知錯誤: {0}"),
        new i18n("Game_Name", "Game Name", "遊戲名稱"),
        new i18n("Global_Activated", "Activated", "啟用"),
        new i18n("Global_Cancel", "Cancel", "取消"),
        new i18n("Global_Company_Name", "SOFTSTAR", "大宇資訊"),
        new i18n("Global_PageNotFound", "Oops... Page Not Found", "歐喔 找不到頁面"),
        new i18n("Global_RTP", "RTP", "RTP"),
        new i18n("Id", "en", "zh_TW"),
        new i18n("IncomeReport_BonusGameCount", "Bonus Count", "Bonus Count"),
        new i18n("IncomeReport_BonusGameWin", "Bonus Win", "Bonus Win"),
        new i18n("IncomeReport_Date", "Date", "日期"),
        new i18n("IncomeReport_FreeGameCount", "Free Count", "Free Count"),
        new i18n("IncomeReport_FreeGameWin", "Free Win", "Free Win"),
        new i18n("IncomeReport_PlayCount", "Play Count", "遊玩次數"),
        new i18n("IncomeReport_PlayerCount", "Player Count", "玩家數量"),
        new i18n("IncomeReport_TotalIncome", "Total Income: ", "總收入: "),
        new i18n("Login_Account", "Account", "帳號"),
        new i18n("Login_ChangePassword", "Change Password", "變更密碼"),
        new i18n("Login_ChangePasswordSuccess", "Change password success, login again", "密碼變更成功，請重新登入"),
        new i18n("Login_Freezed", "Account is locked. Please contact administrator.", "帳號凍結，請聯絡管理員"),
        new i18n("Login_InvalidUserOrPassword", "Invalid login info", "帳號或密碼錯誤"),
        new i18n("Login_Language", "Language", "語言"),
        new i18n("Login_Login", "Login", "登入"),
        new i18n("Login_NewPassword", "Enter new password", "輸入新密碼"),
        new i18n("Login_NewPasswordConfirm", "Enter again", "在輸入一次"),
        new i18n("Login_NewPasswordEmpty", "Enter password", "請輸入新密碼"),
        new i18n("Login_Password", "Password", "密碼"),
        new i18n("Login_PasswordNotMatch", "Password not match", "密碼不一樣"),
        new i18n("Operator_ApiUrl", "Api Url", "Api 地址"),
        new i18n("Operator_Channel", "Channel", "頻道"),
        new i18n("Operator_Name", "Operator Name", "營運商名稱"),
        new i18n("Operator_OperatorPublicKeyInfo", "Fill in the operator's public key here", "將營運商公鑰填於此處"),
        new i18n("Operator_PublicKey", "Operator Public Key", "營運商公鑰"),
        new i18n("Operator_PublicKeyWithName", "Operator Public Key for: {0}", "營運商: {0} 的公鑰"),
        new i18n("Operator_ServerPublicKey", "Provider Public Key", "供應商公鑰"),
        new i18n("Operator_ServerPublicKeyInfo", "Provide this key to operator", "將此公鑰提供給營運商"),
        new i18n("Operator_ServerPublicKeyWithName", "Provider Public Key for Operator: {0}", "營運商: {0} 的供應商公鑰"),
        new i18n("Operator_WalletMode", "Wallet Mode", "錢包模式"),
        new i18n("Operator_WalletModeMono", "Mono", "單一"),
        new i18n("Operator_WalletModeTransfer", "Transfer", "轉帳"),
        new i18n("Privilege_AllPrivilege", "All privilege", "所有權限"),
        new i18n("Privilege_BackStageSetting", "Back Stage settings", "後台設定"),
        new i18n("Privilege_EditGameManagement", "Modify Game Management", "遊戲管理(修改)"),
        new i18n("Privilege_EditRiskControl", "Modify Risk Control", "風險控制(修改)"),
        new i18n("Privilege_EditSiteAccount", "Modify site account", "後台帳號(修改)"),
        new i18n("Privilege_EditSiteOperator", "Modify operator", "營運商(修改)"),
        new i18n("Privilege_EditSiteRole", "Modify site role", "後台角色(修改)"),
        new i18n("Privilege_EditSiteSetting", "Modify site settings", "站點設定(修改)"),
        new i18n("Privilege_GameSetting", "Game settings", "遊戲設定"),
        new i18n("Privilege_SiteAccount", "Site account", "後台帳號"),
        new i18n("Privilege_ViewAuditLog", "View audit log", "操作紀錄"),
        new i18n("Privilege_ViewBetRecord", "View Bet Record", "投注紀錄(讀取)"),
        new i18n("Privilege_ViewGameManagement", "View Game Management", "遊戲管理(讀取)"),
        new i18n("Privilege_ViewIncomeReport", "View Income Report", "損益報表(讀取)"),
        new i18n("Privilege_ViewRiskControl", "View Risk Control", "風險控制(讀取)"),
        new i18n("Privilege_ViewSiteAccount", "View site account", "後台帳號(讀取)"),
        new i18n("Privilege_ViewSiteOperator", "View operator", "營運商管理(讀取)"),
        new i18n("Privilege_ViewSiteRole", "View role", "角色管理(讀取)"),
        new i18n("RiskControl_CurrentRTP", "Current RTP", "目前 RTP"),
        new i18n("RiskControl_ForcedOn", "Force On", "強制開啟"),
        new i18n("RiskControl_RTPThreshold", "RTP Threshold", "RTP 閥值"),
        new i18n("RiskControl_ReRoll", "Re-roll", "重骰"),
        new i18n("RiskControl_ReRollRTP", "Re-roll RTP", "重骰 RTP"),
        new i18n("Role_Name", "Role Name", "角色名稱"),
        new i18n("Role_NoPermission", "No permission", "無權限"),
        new i18n("Role_Permissions", "Permissions", "權限"),
        new i18n("SideMenu_Account", "Site Account", "後台帳號"),
        new i18n("SideMenu_AuditLog", "Audit Log", "操作紀錄"),
        new i18n("SideMenu_BackStageSettings", "Back Stage Settings", "後台設定"),
        new i18n("SideMenu_BetRecord", "Bet Record", "投注紀錄"),
        new i18n("SideMenu_GameManagement", "Game Management", "遊戲管理"),
        new i18n("SideMenu_GameSettings", "Game Settings", "遊戲設定"),
        new i18n("SideMenu_IncomeReport", "Income Report", "損益報告"),
        new i18n("SideMenu_Logout", "Logout", "登出"),
        new i18n("SideMenu_Operator", "Operator Management", "營運商管理"),
        new i18n("SideMenu_RiskControl", "Risk Control", "風險控制"),
        new i18n("SideMenu_Role", "Role Management", "角色管理"),
        new i18n("SideMenu_SiteSettings", "Site Settings", "站點設定"),
        new i18n("SiteSettings_ApiUrl", "Api Url", "Api 地址"),
        new i18n("SiteSettings_ArcadeServer", "Arcade Server", "機台伺服器"),
        new i18n("Test", "Test String", "測試字串")];
    public readonly DataMap: Map<string, i18n> = new Map<string, i18n>();

    public readonly Account_Active: i18n = this.DataArray[0];
    public readonly Account_Deleted: i18n = this.DataArray[1];
    public readonly Account_Freezed: i18n = this.DataArray[2];
    public readonly Account_LastLogin: i18n = this.DataArray[3];
    public readonly Account_Operator: i18n = this.DataArray[4];
    public readonly Account_Role: i18n = this.DataArray[5];
    public readonly Account_Status: i18n = this.DataArray[6];
    public readonly Account_Unfreeze: i18n = this.DataArray[7];
    public readonly Audit_Action: i18n = this.DataArray[8];
    public readonly Audit_AdminName: i18n = this.DataArray[9];
    public readonly Audit_Content: i18n = this.DataArray[10];
    public readonly Audit_Table: i18n = this.DataArray[11];
    public readonly Audit_Time: i18n = this.DataArray[12];
    public readonly BetRecord_BetAmount: i18n = this.DataArray[13];
    public readonly BetRecord_BetTime: i18n = this.DataArray[14];
    public readonly BetRecord_Count: i18n = this.DataArray[15];
    public readonly BetRecord_GameType: i18n = this.DataArray[16];
    public readonly BetRecord_PlayerName: i18n = this.DataArray[17];
    public readonly BetRecord_TransId: i18n = this.DataArray[18];
    public readonly BetRecord_WinAmount: i18n = this.DataArray[19];
    public readonly DataView_Action: i18n = this.DataArray[20];
    public readonly DataView_Add: i18n = this.DataArray[21];
    public readonly DataView_AddWithTitle: i18n = this.DataArray[22];
    public readonly DataView_CopyToClipboard: i18n = this.DataArray[23];
    public readonly DataView_Delete: i18n = this.DataArray[24];
    public readonly DataView_DeleteWithTitle: i18n = this.DataArray[25];
    public readonly DataView_DeleteWithTitleAndName: i18n = this.DataArray[26];
    public readonly DataView_Detail: i18n = this.DataArray[27];
    public readonly DataView_DetailWithTitle: i18n = this.DataArray[28];
    public readonly DataView_Edit: i18n = this.DataArray[29];
    public readonly DataView_EditWithTitle: i18n = this.DataArray[30];
    public readonly DataView_EndTime: i18n = this.DataArray[31];
    public readonly DataView_Filter: i18n = this.DataArray[32];
    public readonly DataView_MoreWithNumber: i18n = this.DataArray[33];
    public readonly DataView_NoDataFound: i18n = this.DataArray[34];
    public readonly DataView_NoOption: i18n = this.DataArray[35];
    public readonly DataView_None: i18n = this.DataArray[36];
    public readonly DataView_Query: i18n = this.DataArray[37];
    public readonly DataView_Remark: i18n = this.DataArray[38];
    public readonly DataView_Save: i18n = this.DataArray[39];
    public readonly DataView_SaveAll: i18n = this.DataArray[40];
    public readonly DataView_StartTime: i18n = this.DataArray[41];
    public readonly Error_ArcadeFetchFailed: i18n = this.DataArray[42];
    public readonly Error_EmptyField: i18n = this.DataArray[43];
    public readonly Error_FormatError: i18n = this.DataArray[44];
    public readonly Error_InvalidMaxLength: i18n = this.DataArray[45];
    public readonly Error_InvalidMaxValue: i18n = this.DataArray[46];
    public readonly Error_InvalidMinLength: i18n = this.DataArray[47];
    public readonly Error_InvalidMinValue: i18n = this.DataArray[48];
    public readonly Error_InvalidRequest: i18n = this.DataArray[49];
    public readonly Error_InvalidToken: i18n = this.DataArray[50];
    public readonly Error_NoOperatorSelect: i18n = this.DataArray[51];
    public readonly Error_OnRiskControllThreshold_3: i18n = this.DataArray[52];
    public readonly Error_OperatorInUse: i18n = this.DataArray[53];
    public readonly Error_RoleInUse: i18n = this.DataArray[54];
    public readonly Error_ServerClosed: i18n = this.DataArray[55];
    public readonly Error_TypeError: i18n = this.DataArray[56];
    public readonly Error_Unauthorized: i18n = this.DataArray[57];
    public readonly Error_Unknown: i18n = this.DataArray[58];
    public readonly Game_Name: i18n = this.DataArray[59];
    public readonly Global_Activated: i18n = this.DataArray[60];
    public readonly Global_Cancel: i18n = this.DataArray[61];
    public readonly Global_Company_Name: i18n = this.DataArray[62];
    public readonly Global_PageNotFound: i18n = this.DataArray[63];
    public readonly Global_RTP: i18n = this.DataArray[64];
    public readonly Id: i18n = this.DataArray[65];
    public readonly IncomeReport_BonusGameCount: i18n = this.DataArray[66];
    public readonly IncomeReport_BonusGameWin: i18n = this.DataArray[67];
    public readonly IncomeReport_Date: i18n = this.DataArray[68];
    public readonly IncomeReport_FreeGameCount: i18n = this.DataArray[69];
    public readonly IncomeReport_FreeGameWin: i18n = this.DataArray[70];
    public readonly IncomeReport_PlayCount: i18n = this.DataArray[71];
    public readonly IncomeReport_PlayerCount: i18n = this.DataArray[72];
    public readonly IncomeReport_TotalIncome: i18n = this.DataArray[73];
    public readonly Login_Account: i18n = this.DataArray[74];
    public readonly Login_ChangePassword: i18n = this.DataArray[75];
    public readonly Login_ChangePasswordSuccess: i18n = this.DataArray[76];
    public readonly Login_Freezed: i18n = this.DataArray[77];
    public readonly Login_InvalidUserOrPassword: i18n = this.DataArray[78];
    public readonly Login_Language: i18n = this.DataArray[79];
    public readonly Login_Login: i18n = this.DataArray[80];
    public readonly Login_NewPassword: i18n = this.DataArray[81];
    public readonly Login_NewPasswordConfirm: i18n = this.DataArray[82];
    public readonly Login_NewPasswordEmpty: i18n = this.DataArray[83];
    public readonly Login_Password: i18n = this.DataArray[84];
    public readonly Login_PasswordNotMatch: i18n = this.DataArray[85];
    public readonly Operator_ApiUrl: i18n = this.DataArray[86];
    public readonly Operator_Channel: i18n = this.DataArray[87];
    public readonly Operator_Name: i18n = this.DataArray[88];
    public readonly Operator_OperatorPublicKeyInfo: i18n = this.DataArray[89];
    public readonly Operator_PublicKey: i18n = this.DataArray[90];
    public readonly Operator_PublicKeyWithName: i18n = this.DataArray[91];
    public readonly Operator_ServerPublicKey: i18n = this.DataArray[92];
    public readonly Operator_ServerPublicKeyInfo: i18n = this.DataArray[93];
    public readonly Operator_ServerPublicKeyWithName: i18n = this.DataArray[94];
    public readonly Operator_WalletMode: i18n = this.DataArray[95];
    public readonly Operator_WalletModeMono: i18n = this.DataArray[96];
    public readonly Operator_WalletModeTransfer: i18n = this.DataArray[97];
    public readonly Privilege_AllPrivilege: i18n = this.DataArray[98];
    public readonly Privilege_BackStageSetting: i18n = this.DataArray[99];
    public readonly Privilege_EditGameManagement: i18n = this.DataArray[100];
    public readonly Privilege_EditRiskControl: i18n = this.DataArray[101];
    public readonly Privilege_EditSiteAccount: i18n = this.DataArray[102];
    public readonly Privilege_EditSiteOperator: i18n = this.DataArray[103];
    public readonly Privilege_EditSiteRole: i18n = this.DataArray[104];
    public readonly Privilege_EditSiteSetting: i18n = this.DataArray[105];
    public readonly Privilege_GameSetting: i18n = this.DataArray[106];
    public readonly Privilege_SiteAccount: i18n = this.DataArray[107];
    public readonly Privilege_ViewAuditLog: i18n = this.DataArray[108];
    public readonly Privilege_ViewBetRecord: i18n = this.DataArray[109];
    public readonly Privilege_ViewGameManagement: i18n = this.DataArray[110];
    public readonly Privilege_ViewIncomeReport: i18n = this.DataArray[111];
    public readonly Privilege_ViewRiskControl: i18n = this.DataArray[112];
    public readonly Privilege_ViewSiteAccount: i18n = this.DataArray[113];
    public readonly Privilege_ViewSiteOperator: i18n = this.DataArray[114];
    public readonly Privilege_ViewSiteRole: i18n = this.DataArray[115];
    public readonly RiskControl_CurrentRTP: i18n = this.DataArray[116];
    public readonly RiskControl_ForcedOn: i18n = this.DataArray[117];
    public readonly RiskControl_RTPThreshold: i18n = this.DataArray[118];
    public readonly RiskControl_ReRoll: i18n = this.DataArray[119];
    public readonly RiskControl_ReRollRTP: i18n = this.DataArray[120];
    public readonly Role_Name: i18n = this.DataArray[121];
    public readonly Role_NoPermission: i18n = this.DataArray[122];
    public readonly Role_Permissions: i18n = this.DataArray[123];
    public readonly SideMenu_Account: i18n = this.DataArray[124];
    public readonly SideMenu_AuditLog: i18n = this.DataArray[125];
    public readonly SideMenu_BackStageSettings: i18n = this.DataArray[126];
    public readonly SideMenu_BetRecord: i18n = this.DataArray[127];
    public readonly SideMenu_GameManagement: i18n = this.DataArray[128];
    public readonly SideMenu_GameSettings: i18n = this.DataArray[129];
    public readonly SideMenu_IncomeReport: i18n = this.DataArray[130];
    public readonly SideMenu_Logout: i18n = this.DataArray[131];
    public readonly SideMenu_Operator: i18n = this.DataArray[132];
    public readonly SideMenu_RiskControl: i18n = this.DataArray[133];
    public readonly SideMenu_Role: i18n = this.DataArray[134];
    public readonly SideMenu_SiteSettings: i18n = this.DataArray[135];
    public readonly SiteSettings_ApiUrl: i18n = this.DataArray[136];
    public readonly SiteSettings_ArcadeServer: i18n = this.DataArray[137];
    public readonly Test: i18n = this.DataArray[138];

    constructor() {
        for (const item of this.DataArray) {
            this.DataMap.set(item.Id, item);
        }
    }
}

export const i18nSheet = new DataSheet();
