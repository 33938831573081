import { useSignals } from "@preact/signals-react/runtime";
import DataView from "../../components/DataView/DataView";
import EnumQuery from "../../components/DataView/Query/EnumQuery";
import StringQuery from "../../components/DataView/Query/StringQuery";
import { SwitchTableRenderer } from "../../components/DataView/TableRenderer/SwitchTableRenderer";
import { SaveButton } from "../../components/IconButton";
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { DataViewColumn } from "../../model/DataView";
import { User } from "../../network/Account";
import { GetOperators } from "../../network/Operator";
import { Get, Post } from "../../Utils/net";

const EditPermission = 'EditGameManagement';

export default function GameMgmt() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.Game_Name,
            Field: 'Name',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.Account_Operator,
            Field: 'Operator',
            TableRenderer: User?.value?.IsSiteOperator ? (data, column) => <>{data[column.Field]}</> : undefined
        },
        {
            Name: i18nSheet.Global_Activated,
            Field: 'Active',
            TableRenderer: (data, column, control) => <><SwitchTableRenderer data={data} col={column} controls={control.Row} editable /></>

        }
    ]
    if (User.value?.Permissions.includes(EditPermission)) {
        columns.push({
            Name: i18nSheet.DataView_Action,
            Field: '',
            TableRenderer: (data, col, control) => {
                return <>
                    <SaveButton disabled={!control.Row.HasNewData(data)} row={data} controls={control.Row} />
                </>
            }
        });
    }

    return <DataView
        Queries={[
            new EnumQuery({ Label: i18nSheet.Account_Operator, Field: 'Operator', EnumValues: GetOperators, Disabled: !User.value?.IsSiteOperator }),
            new StringQuery({ Label: i18nSheet.Game_Name, Field: 'Name' }),
        ]}
        QueryFunction={async (queries, page) => {
            const req: Record<string, string> = { ...page }
            if (User.value?.IsSiteOperator && queries.OperatorId) {
                req.OperatorId = queries.OperatorId;
            }
            if (queries.Name) {
                req.Name = queries.Name;
            }
            return await Get('/api/game/games', req)
        }}
        AutoLoading
        EditPermission={EditPermission}
        Columns={columns}
        InTableEdit
        DataIdField="Id"
        ModifyData={async (row, data) => {
            let operatorId: number | undefined;
            if (User.value?.IsSiteOperator) {
                operatorId = row.OperatorId;
            }
            return await Post('/api/game/modifyGame', { ...data, Id: row.Id, OperatorId: operatorId })
        }}
    ></DataView>
}