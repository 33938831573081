import { useEffect, useState } from "react";
import { i18nSheet } from "../dataSheets/generated/i18n";
import { LocalStorageKey_Token, LoggedIn } from "../network/Account";

export async function Get(url: string, params?: Record<string, string>) {
    const token = window.localStorage.getItem(LocalStorageKey_Token)
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Access-Token'] = token
    }

    if (params) {
        url += '?' + new URLSearchParams(params).toString()
    }

    try {
        const res = await fetch(url, {
            headers: headers
        })
        return await middleware(res);
    } catch {
        return {
            Success: false,
            Error: i18nSheet.Error_Unknown
        };
    }

}

export async function Post(url: string, data: unknown) {
    const token = window.localStorage.getItem(LocalStorageKey_Token)
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
    }
    if (token) {
        headers['Access-Token'] = token
    }
    try {
        const res = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(data)

        });
        return await middleware(res);
    } catch (e) {
        return {
            Success: false,
            Error: i18nSheet.Error_Unknown
        };
    }
}

export function UseGet(url: string, params?: Record<string, string>): { isLoading: boolean, data: any, reload: () => void } {
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<any>();
    const [reload, setReload] = useState(0);

    useEffect(() => {
        let u = url;
        const controller = new AbortController();
        const token = window.localStorage.getItem(LocalStorageKey_Token)
        const headers: Record<string, string> = {
            'Content-Type': 'application/json',
        }
        if (token) {
            headers['Access-Token'] = token
        }

        if (params) {
            u += '?' + new URLSearchParams(params).toString()
        }

        fetch(u, {
            headers: headers,
            signal: controller.signal
        }).then(middleware).then((data) => {
            setData(data);
            setIsLoading(false);
        }).catch(() => {
            setData(undefined);
            setIsLoading(false);
        });
        return () => {
            controller.abort();
        }
    }, [params, url, reload]);

    return { isLoading, data, reload: () => setReload((r) => r + 1) };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function middleware(res: Response): Promise<any> {
    if (res.status === 401) {
        LoggedIn.value = false;
        window.localStorage.removeItem(LocalStorageKey_Token);
    }
    try {
        return await res.json();
    } catch (e) {
        return {
            Success: false,
            Error: i18nSheet.Error_Unknown
        };
    }
}