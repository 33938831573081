import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import KeyIcon from '@mui/icons-material/Key';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import Alert from '@mui/material/Alert';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useSignals } from "@preact/signals-react/runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import DataView from "../../components/DataView/DataView";
import SelectColumnViewer from '../../components/DataView/ViewRenderer/SelectColumnViewer';
import TextColumnViewer from "../../components/DataView/ViewRenderer/TextColumnViewer";
import { CustomButton, DeleteButton, EditButton, ViewButton } from "../../components/IconButton";
import { Text } from "../../components/Text";
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";
import { DataViewColumn, DataViewEditorControl } from "../../model/DataView";
import { User } from "../../network/Account";
import { Get, Post, UseGet } from "../../Utils/net";

const EditPermission = 'EditSiteOperator';

export default function Operator() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.Operator_Name,
            Field: 'Name',
            TableRenderer: (data, column) => <>{data[column.Field]}</>,
            ViewRenderer: new TextColumnViewer(i18nSheet.Operator_Name)
        },
        {
            Name: i18nSheet.Operator_ApiUrl,
            Field: 'ApiUrl',
            ViewRenderer: new TextColumnViewer(i18nSheet.Operator_ApiUrl)
        },
        {
            Name: i18nSheet.Operator_PublicKey,
            Field: 'OperatorPublicKey',
            ViewRenderer: new TextColumnViewer(i18nSheet.Operator_PublicKey, { Multiline: true, MinRows: 3, HideInViewMode: true, HideInEditMode: true })
        },
        {
            Name: i18nSheet.Operator_Channel,
            Field: 'Channel',
            TableRenderer: (data, column) => <>{data[column.Field]}</>,
        },
        {
            Name: i18nSheet.Operator_WalletMode,
            Field: 'WalletType',
            TableRenderer: (data, column) => <>{getWalletModeDisplay(data[column.Field])}</>,
            ViewRenderer: new SelectColumnViewer(i18nSheet.Operator_WalletMode, [[0, i18nSheet.Operator_WalletModeMono.Id], [1, i18nSheet.Operator_WalletModeTransfer.Id]], { I18n: true })
        },
        {
            Name: i18nSheet.DataView_Remark,
            Field: 'Remark',
            TableRenderer: (data, column) => <Text>{data[column.Field]}</Text>,
            ViewRenderer: new TextColumnViewer(i18nSheet.DataView_Remark, { Multiline: true, MinRows: 3 })
        },
        {
            Name: i18nSheet.DataView_Action,
            Field: '',
            TableRenderer: (data, col, control) => {
                if (User.value?.Permissions.includes('EditSiteRole') === true) {
                    return <>
                        <EditButton data={data} controls={control.Editor} />
                        <CustomButton data={data} controls={control.Editor} tooltip={[i18nSheet.DataView_EditWithTitle, i18nSheet.Operator_PublicKey]} icon={KeyIcon} renderer={(data, controls, click) => <OperatorPublicKeyEditor data={data} controls={controls} click={click} />} />
                        <CustomButton data={data} controls={control.Editor} tooltip={i18nSheet.Operator_ServerPublicKey} icon={KeyIcon} renderer={(data, controls, click) => <ServerPublicKeyView data={data} controls={controls} click={click} />} />
                        <DeleteButton data={data} controls={control.Editor} />
                    </>
                }
                return <>
                    <ViewButton controls={control.Editor} data={data} />
                </>
            }
        }
    ]
    return <DataView
        AutoLoading
        Queries={[]}
        QueryFunction={async () => {
            return await Get('/api/operator/operators');
        }}
        Columns={columns}
        EditPermission={EditPermission} DataTitle={i18nSheet.Account_Operator}
        AddData={async (data) => {
            return await Post('/api/operator/addOperator', data);
        }}
        DeleteData={async (row) => {
            return await Post('/api/operator/deleteOperator', { OperatorId: row.Id });
        }}
        EditorTitleColumn="Name"
        ModifyData={async (row, data) => {
            return await Post('/api/operator/editOperator', { OperatorId: row.Id, ...data });
        }}
    />
}

type OperatorPublicKeyEditorProps = {
    data: any;
    controls: DataViewEditorControl;
    click: any
}

function OperatorPublicKeyEditor(props: OperatorPublicKeyEditorProps) {
    const params = useMemo(() => { return { OperatorId: props.data.Id } }, [props.data.Id, props.click]);
    const { isLoading: isDataLoading, data } = UseGet('/api/operator/operatorPublicKey', params);
    const [isLoading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (data?.OperatorPublicKey) {
            setValue(data.OperatorPublicKey);
        } else {
            setValue('');
        }
        setError(undefined);
    }, [data, props.click])

    async function onEditSubmit(e: React.FormEvent) {
        e.preventDefault();
        if (value === data?.OperatorPublicKey || (!value && !data?.OperatorPublicKey)) {
            props.controls.CloseEdit();
            return;
        }
        setLoading(true);
        const res = await Post('/api/operator/editOperatorPublicKey', { OperatorId: props.data.Id, Key: value });
        if (res?.Success) {
            props.controls.CloseEdit();
        } else {
            setError("Error");
        }
        setLoading(false);
    }

    return isDataLoading ? <LinearProgress /> : <>
        <IconButton sx={{ position: 'absolute', right: 0, top: 0, borderRadius: '0 0 0 4px', '&:hover': { background: (theme) => theme.palette.error.dark } }} onClick={() => props.controls.CloseEdit()} disabled={isLoading}><CloseIcon /></IconButton>
        <Box sx={{ background: 'rgb(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" sx={{ my: 1 }}>{GetText(i18nSheet.DataView_EditWithTitle, GetText(i18nSheet.Operator_PublicKey))}</Typography>
        </Box>
        <Divider />
        <form onSubmit={onEditSubmit} style={{ overflow: 'auto' }}>
            <Stack sx={{ display: "flex", margin: 2, gap: 2, overflow: 'auto' }}>
                <Alert severity="info">{GetText(i18nSheet.Operator_OperatorPublicKeyInfo)}</Alert>
                <TextField error={!!error} helperText={error} multiline variant="filled" value={value} label={GetText(i18nSheet.Operator_PublicKey)} onChange={(e) => { setValue(e.target.value); setError(undefined); }} />
                <Stack direction="row" sx={{ justifyContent: 'end' }}>
                    <LoadingButton variant="contained" type="submit" loading={isLoading}><Tooltip title={GetText(i18nSheet.DataView_Edit)} arrow disableInteractive placement="top">
                        <EditIcon />
                    </Tooltip></LoadingButton>
                </Stack>
            </Stack>
        </form>
    </>
}

type ServerPublicKeyProps = {
    data: any;
    controls: DataViewEditorControl;
    click: any
}

function ServerPublicKeyView(props: ServerPublicKeyProps) {
    const params = useMemo(() => { return { OperatorId: props.data.Id } }, [props.data.Id, props.click]);
    const { isLoading: isDataLoading, data } = UseGet('/api/operator/serverPublicKey', params);
    const textFieldRef = useRef<HTMLTextAreaElement>(null);
    const select = () => {
        textFieldRef.current?.select()
    }
    const copyToClipboard = () => {
        window.navigator.clipboard.writeText(data?.ServerPublicKey);
    }

    return isDataLoading ? <LinearProgress /> : <>
        <IconButton sx={{ position: 'absolute', right: 0, top: 0, borderRadius: '0 0 0 4px', '&:hover': { background: (theme) => theme.palette.error.dark } }} onClick={() => props.controls.CloseEdit()}><CloseIcon /></IconButton>
        <Box sx={{ background: 'rgb(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" sx={{ my: 1 }}>{GetText(i18nSheet.Operator_ServerPublicKeyWithName, props.data.Name)}</Typography>
        </Box>
        <Divider />
        <form style={{ overflow: 'auto' }}>
            <Stack sx={{ display: "flex", margin: 2, gap: 2, overflow: 'auto' }}>
                <Alert severity="info">{GetText(i18nSheet.Operator_ServerPublicKeyInfo)}</Alert>
                <TextField inputRef={textFieldRef} multiline variant="filled" value={data?.ServerPublicKey} label={GetText(i18nSheet.Operator_ServerPublicKey)} onFocus={select} onClick={select} />
                <Stack direction="row" sx={{ justifyContent: 'end' }}>
                    <Button variant="contained" onClick={copyToClipboard}><Tooltip title={GetText(i18nSheet.DataView_CopyToClipboard)} arrow disableInteractive placement="top">
                        <ContentCopyIcon />
                    </Tooltip></Button>
                </Stack>
            </Stack>
        </form>
    </>
}

function getWalletModeDisplay(mode: number) {
    switch (mode) {
        case 0:
            return GetText(i18nSheet.Operator_WalletModeMono);
        case 1:
            return GetText(i18nSheet.Operator_WalletModeTransfer);
        default:
            return '';
    }
}