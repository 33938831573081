import { Query, QueryDropRendererProps, QueryProps } from "./QueryBox";

export type StringQueryProps = QueryProps & {
    //
}

export default class StringQuery extends Query {
    constructor(props: StringQueryProps) {
        super(props);
    }

    DropRenderer: (props: QueryDropRendererProps) => null = (props) => {
        return null;
    }

    OnInputBlur(value: string) {
        return { newValue: value };
    }
}