import Box from "@mui/material/Box";
import { i18nSheet } from "../dataSheets/generated/i18n";
import { GetText } from "../dataSheets/i18n";

export default function NotFoundPage() {
    return <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        fontSize: 45,
        fontWeight: 'bold',
        color: (theme)=> theme.palette.text.disabled
    }}>{GetText(i18nSheet.Global_PageNotFound)}</Box>
}