import type { SvgIconComponent } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BusinessIcon from '@mui/icons-material/Business';
import CasinoIcon from '@mui/icons-material/Casino';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import GroupIcon from '@mui/icons-material/Group';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import SpeedIcon from '@mui/icons-material/Speed';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { signal } from '@preact/signals-react';
import { useSignals } from '@preact/signals-react/runtime';
import { ElementType, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SoftStarLogo from '../assets/softStarLogo.svg';
import { i18n, i18nSheet } from '../dataSheets/generated/i18n';
import { CurrentLanguage, GetText, Language, SetLanguage } from '../dataSheets/i18n';
import { Logout, User } from '../network/Account';
import { Link } from './Link';

type SideMenuItemType = SideMenuNodeItem | SideMenuLeafItem;

type SideMenuNodeItem = {
    label: i18n
    permission?: string[]
    child: SideMenuItemType[]
}

type SideMenuLeafItem = {
    icon: SvgIconComponent,
    label: i18n
    permission?: string[]
    path: string
};

export const IsSideMenuOpen = signal(false);

const sideMenuData: SideMenuItemType[] = [
    {
        label: i18nSheet.SideMenu_GameSettings, permission: [], child: [
            { icon: CasinoIcon, label: i18nSheet.SideMenu_GameManagement, permission: ['ViewGameManagement'], path: '/game_settings/game_management' },
            { icon: SpeedIcon, label: i18nSheet.SideMenu_RiskControl, permission: ['ViewRiskControl'], path: '/game_settings/risk_control' },
            { icon: ReceiptIcon, label: i18nSheet.SideMenu_BetRecord, permission: ['ViewBetRecord'], path: '/game_settings/bet_record' },
            { icon: AttachMoneyIcon, label: i18nSheet.SideMenu_IncomeReport, permission: ['ViewIncomeReport'], path: '/game_settings/income_report' }
        ]
    },
    {
        label: i18nSheet.SideMenu_BackStageSettings, permission: [], child: [
            { icon: ManageAccountsIcon, label: i18nSheet.SideMenu_Account, permission: ['ViewSiteAccount'], path: '/settings/account' },
            { icon: GroupIcon, label: i18nSheet.SideMenu_Role, permission: ['ViewSiteRole'], path: '/settings/role' },
            { icon: BusinessIcon, label: i18nSheet.SideMenu_Operator, permission: ['ViewSiteOperator'], path: '/settings/operator', },
            { icon: SettingsIcon, label: i18nSheet.SideMenu_SiteSettings, permission: ['EditSiteSetting'], path: '/settings/site', },
            { icon: ReceiptIcon, label: i18nSheet.SideMenu_AuditLog, permission: ['ViewAuditLog'], path: '/settings/audit_log' },
        ]
    },
]

const Navigator = styled(List)<{ component?: ElementType }>({
    '& .MuiListItemButton-root': {
        paddingLeft: 24,
        paddingRight: 24,
    },
    '& .MuiListItemIcon-root': {
        minWidth: 0,
        marginRight: 16,
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20,
    },
});

type SideMenuItemsProps = {
    items: SideMenuItemType[]
    defaultOpenIndices?: number[]
    ml?: number;
}

type SideMenuNodeItemProps = {
    item: SideMenuNodeItem
    defaultOpen: boolean
    openIndices?: number[]
    child: SideMenuItemType[]
    ml: number;
}

const marginLeft = 5;

function SideMenuNodeItem(props: SideMenuNodeItemProps) {
    const [isOpen, setIsOpen] = useState(props.defaultOpen);

    return <Box key={props.item.label.Id} sx={{ width: '256px', bgcolor: isOpen ? 'rgba(71, 98, 130, 0.2)' : null, transition: 'background 0.2s ease-in-out', boxShadow: 'inset rgb(0, 0, 0, 0.5) -3px 0px 10px 0px' }}>
        <ListItemButton sx={{ py: 2 }} alignItems="flex-start" onClick={() => { setIsOpen((isOpen) => !isOpen) }}>
            <ListItemText primary={GetText(props.item.label)} primaryTypographyProps={{ fontSize: 15, fontWeight: 'medium', lineHeight: '20px', mb: '2px', ml: props.ml }} sx={{ my: 0 }} />
            <KeyboardArrowDown sx={{ mr: -1, opacity: 0.5, transform: isOpen ? 'rotate(-180deg)' : 'rotate(-90deg)', transition: '0.2s' }} />
        </ListItemButton>
        <Box sx={{ display: 'grid', gridTemplateRows: isOpen ? '1fr' : '0fr', transition: 'grid-template-rows 0.25s ease-in-out' }}>
            <Box sx={{ overflow: 'hidden' }}>
                <SideMenuItems items={props.child} defaultOpenIndices={props.openIndices} ml={props.ml + marginLeft} />
            </Box>
        </Box>
    </Box>
}

function SideMenuItems(props: SideMenuItemsProps) {
    const location = useLocation();
    const { items, defaultOpenIndices } = props;
    const ml = props.ml ?? 0;

    return <>{
        items.map((item, index) => {
            const isOpen = defaultOpenIndices ? defaultOpenIndices[0] === index : false;

            if (isNode(item)) {
                return <SideMenuNodeItem key={item.label.Id} item={item} defaultOpen={isOpen} openIndices={defaultOpenIndices?.slice(1)} child={item.child} ml={ml} />
            } else {
                return <ListItemButton key={item.label.Id} sx={{ minHeight: 32, color: 'rgba(255,255,255,.8)', '&.Mui-disabled': { backgroundColor: (theme) => theme.palette.primary.dark, color: 'rgba(255,255,255,.8)', opacity: 1, boxShadow: 'inset rgb(0, 0, 0, 0.5) -3px 0px 4px 0px' } }} component={Link} onClick={() => { IsSideMenuOpen.value = false }}
                    to={item.path}
                    disabled={location.pathname === item.path}>
                    <ListItemIcon sx={{ color: 'inherit', ml: ml }}>
                        <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={GetText(item.label)} primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }} sx={{
                        '.MuiListItemText-primary': {
                            overflow: 'clip',
                            textOverflow: 'ellipsis',
                            textWrap: 'nowrap'
                        }
                    }} />
                </ListItemButton>
            }
        })
    }</>
}

export default function SideMenu() {
    useSignals();
    const filteredData = useMemo(() => filterMenuItems(sideMenuData), []);
    const openIndices = useMemo(() => getOpenIndices(filteredData, window.location.pathname), [filteredData]);

    return (
        <Box id="sideMenu" sx={{ display: 'flex' }}>
            <Box id="sideMenu"></Box>
            <Paper elevation={0} sx={{ width: 256, display: 'flex' }}>
                <Navigator sx={{ overflow: 'hidden', display: 'flex', flexDirection: 'column' }} component="nav" >
                    <ListItemButton sx={{ flexGrow: 0 }} component={Link} to="/">
                        <ListItemIcon sx={{ fontSize: 20 }}><img style={{ width: '2rem', height: '2rem' }} src={SoftStarLogo} /></ListItemIcon>
                        <ListItemText
                            sx={{ my: 0 }}
                            primary={GetText(i18nSheet.Global_Company_Name)}
                            primaryTypographyProps={{
                                fontSize: 20,
                                fontWeight: 'medium',
                                letterSpacing: 0,
                            }}
                        />
                    </ListItemButton>
                    <Divider />
                    <ListItem component="div" disablePadding>
                        <ListItemButton sx={{ height: 56 }} component={Link} to="/user">
                            <ListItemIcon>
                                <AccountCircleIcon color="primary" />
                            </ListItemIcon>
                            <ListItemText
                                primary={User.value?.Name}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    fontWeight: 'medium',
                                    variant: 'body2',
                                }}
                            />
                        </ListItemButton>
                        <Tooltip title={GetText(i18nSheet.SideMenu_Logout)} arrow disableInteractive placement="top">
                            <IconButton
                                size="large"
                                sx={{
                                    '& svg': {
                                        color: 'rgba(255,255,255,0.8)',
                                        transition: '0.2s',
                                        transform: 'translateX(0)',
                                    },
                                    '&:hover, &:focus': {
                                        bgcolor: 'unset',
                                        '& svg:first-of-type': {
                                            transform: 'translateX(-4px)',
                                        },
                                        '& svg:last-of-type': {
                                            right: 0,
                                            opacity: 1,
                                        },
                                    },
                                    '&::after': {
                                        content: '""',
                                        position: 'absolute',
                                        height: '80%',
                                        display: 'block',
                                        left: 0,
                                        width: '1px',
                                        bgcolor: 'divider',
                                    },
                                }}
                                onClick={() => { Logout(); }}
                            >
                                <LogoutIcon />
                                <DirectionsRunIcon sx={{ position: 'absolute', right: 4, opacity: 0 }} />
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    <Divider />
                    <Box sx={{ overflow: 'hidden auto', scrollbarWidth: 'thin', flexGrow: 1 }}>
                        <SideMenuItems items={filteredData} defaultOpenIndices={openIndices} />
                    </Box>
                    <FormControl sx={{ alignSelf: 'end' }} size="small">
                        <InputLabel>{GetText(i18nSheet.Login_Language)}</InputLabel>
                        <Select
                            value={CurrentLanguage.value}
                            label={GetText(i18nSheet.Login_Language)}
                            onChange={(e) => {
                                SetLanguage(e.target.value as Language);
                            }}>
                            <MenuItem value="en">English</MenuItem>
                            <MenuItem value="zh_TW">繁體中文</MenuItem>
                        </Select>
                    </FormControl>
                </Navigator>
            </Paper>
        </Box>
    );
}

function isNode(item: SideMenuItemType): item is SideMenuNodeItem {
    return (item as SideMenuNodeItem).child !== undefined;
}

function getOpenIndices(data: SideMenuItemType[], path: string): number[] {
    for (let i = 0; i < data.length; i++) {
        const item = data[i];
        if (isNode(item)) {
            const childIndices = getOpenIndices(item.child, path);
            if (childIndices.length > 0) {
                return [i, ...childIndices]
            }
        } else {
            if (item.path === path) {
                return [i];
            }
        }
    }
    return [];
}

function filterMenuItems(data: SideMenuItemType[]): SideMenuItemType[] {
    return data.filter(item => {
        if (isNode(item)) {
            item.child = filterMenuItems(item.child);
            return item.child.length > 0;
        } else {
            return item.permission === undefined || item.permission.some(p => User.value?.Permissions.includes(p));
        }
    });
}