import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import LinearProgress from '@mui/material/LinearProgress';
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import dayjs from 'dayjs';
import "dayjs/locale/zh-tw";
import utc from 'dayjs/plugin/utc';
import { createRef, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { i18n, i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";
import { DataViewAction, DataViewColumn, DataViewControl, DataViewEditorControl, DataViewEditorSize, DataViewEditProps, DataViewRowControl, QueryFunctionType } from "../../model/DataView";
import { User } from "../../network/Account";
import { WithRequired } from "../../Utils/utils";
import { SnackError } from '../Snackbar';
import { Query, QueryBox } from './Query/QueryBox';
dayjs.extend(utc);

type DataViewProps = {
    /** 查詢條件 */
    Queries: Query[];
    /** 查詢函數 */
    QueryFunction: QueryFunctionType;

    /** 表格欄位設定 */
    Columns: DataViewColumn[],
    /** 是否在開啟頁面時自動載入 */
    AutoLoading?: boolean;

    /** 新增資料所需的權限 */
    EditPermission?: string;

    /** 資料名稱 */
    DataTitle?: i18n;

    AdditionalBlock?: (rawResponse: any) => (() => JSX.Element) | null;

    AddData?: (data: any) => Promise<any>;
    ModifyData?: (row: any, data: any) => Promise<any>;
    DeleteData?: (row: any) => Promise<any>;

    /** 用於顯示在編輯視窗的標題欄位 */
    EditorTitleColumn?: string;
} & ({
    /** 開啟在表單中編輯的功能 */
    InTableEdit: true
    /** data 中用作 Id 的欄位 */
    DataIdField: string;
    ModifyData: (row: any, data: any) => Promise<any>;
} | {
    /** 開啟在表單中編輯的功能 */
    InTableEdit?: false
})

const pageSize = 25;

export default function DataView(props: DataViewProps) {
    const [searchParams, setSearchParams] = useSearchParams(); 
    
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any[] | undefined>(undefined);
    const [rawResponse, setRawResponse] = useState<any | undefined>(undefined);
    let paramPage = parseInt(searchParams.get('PageIndex') || '1')
    if (isNaN(paramPage)) {
        paramPage = 1;
    }
    const [page, setPage] = useState(paramPage);
    const [totalPage, setTotalPage] = useState(0);
    const [edit, setEdit] = useState<DataViewEditProps | undefined>(undefined);
    const [editClassName, setEditClassName] = useState('editorInit');
    const [newData, setNewData] = useState<Record<string, any> | undefined>(undefined);
    const [errorData, setErrorData] = useState<{ [k: string]: { Error: string, ErrorParams: any } } | undefined>(undefined);
    const [newDataInTable, setNewDataInTable] = useState<Record<string | number, Record<string, any>> | undefined>(undefined);

    const [reFetch, setReFetch] = useState({});
    const hasEditPermission = props.EditPermission !== undefined && (User.value?.Permissions?.includes(props.EditPermission) ?? false);

    useEffect(() => {
        setNewData(undefined);
        setErrorData(undefined);
    }, [edit])

    const editorControl: DataViewEditorControl = {
        async CloseEdit(refreshData = false) {
            setEditClassName('editorClose');
            if (refreshData) {
                setReFetch({});
            }
        },
        OpenEdit(data) {
            if (!hasEditPermission) {
                return;
            }
            setEdit({
                Action: DataViewAction.Edit, Data: data, ForceUpdate: {}
            })
            setEditClassName('editorOpen')
        },
        OpenView(data) {
            setEdit({ Action: DataViewAction.View, Data: data, ForceUpdate: {} });
            setEditClassName('editorOpen')
        },
        OpenDelete(data) {
            setEdit({ Action: DataViewAction.Delete, Data: data, ForceUpdate: {} });
            setEditClassName('editorOpen')
        },
        OpenAdd() {
            setEdit({ Action: DataViewAction.Add, ForceUpdate: {} });
            setEditClassName('editorOpen')
        },
        OpenCustom(data, renderer, size = DataViewEditorSize.Normal) {
            setEdit({ Action: DataViewAction.Custom, Data: data, Renderer: renderer, ForceUpdate: {}, Size: size });
            setEditClassName('editorOpen')
        },
        SetNewData(key, value) {
            setErrorData((errorData: any) => {
                const newErrorData = { ...errorData };
                delete newErrorData[key];
                return newErrorData;
            })
            setNewData((newData: any) => {
                const n = { ...newData, [key]: value };
                if (value === undefined) {
                    delete n[key];
                }
                return { ...newData, [key]: value }
            })
        },
        GetEditValue(key) {
            if (newData === undefined || newData[key] === undefined) {
                if (edit?.Action !== DataViewAction.Add) {
                    return edit?.Data[key];
                }
                return undefined
            }
            return newData[key];
        },
        SetError(key, error, errorParams) {
            setErrorData((errorData: any) => { return { ...errorData, [key]: { Error: error, ErrorParams: errorParams } } })
        },
        GetError(key) {
            return errorData?.[key];
        },
    }

    const rowControl: DataViewRowControl = {
        SetNewValue(row: any, key, value) {
            setNewDataInTable((newDataInTable: any) => {
                if (!props.InTableEdit) { throw new Error('Dataview is not editable in table') }
                const id = row[props.DataIdField];
                const newRow = { ...newDataInTable?.[id] }

                if (row[key] === value) {
                    delete newRow[key];
                } else {
                    newRow[key] = value
                }

                if (Object.keys(newRow).length === 0) {
                    const newTable = { ...newDataInTable };
                    delete newTable[id];
                    return newTable;
                }

                return { ...newDataInTable, [id]: newRow }
            })
        },
        GetNewValue(row: any, key) {
            if (!props.InTableEdit) { throw new Error('Dataview is not editable in table') }
            return newDataInTable?.[row[props.DataIdField]]?.[key];
        },
        HasNewData(row: any) {
            if (!props.InTableEdit) { throw new Error('Dataview is not editable in table') }
            return newDataInTable?.[row[props.DataIdField]] !== undefined;
        },
        async Save(row: any) {
            if (!props.InTableEdit) { throw new Error('Dataview is not editable in table') }
            const newData = newDataInTable?.[row[props.DataIdField]];
            if (newData === undefined || Object.keys(newData).length === 0) {
                return;
            }
            setLoading(true);
            try {
                const res = await props.ModifyData(row, newData);
                if (res.Success) {
                    const newTableData = { ...newDataInTable };
                    delete newTableData[row[props.DataIdField]];
                    setReFetch({});
                    setNewDataInTable(newTableData);
                }
            } catch {
                SnackError(i18nSheet.Error_Unknown);
            }
            setLoading(false);
        },
        IsLoading: loading,
        HasEditPermission: hasEditPermission
    };

    const control: DataViewControl = {
        Editor: editorControl,
        Row: rowControl,
        Table: { Data: data ?? [], SetData: setData }
    }

    const editRowRenderer = () => {
        if (edit == undefined) {
            return null;
        }
        const withView = props.Columns.filter((column): column is WithRequired<DataViewColumn, 'ViewRenderer'> => column.ViewRenderer !== undefined)
        switch (edit.Action) {
            case DataViewAction.Add:
                return <>
                    {withView.filter((col) => col.ViewRenderer.RenderAdd !== undefined).map((col) => col.ViewRenderer.RenderAdd!(col, editorControl, edit.ForceUpdate))}
                    <Stack direction="row" sx={{ justifyContent: 'end' }}>
                        <LoadingButton variant="contained" type="submit" loading={loading}><Tooltip title={GetText(i18nSheet.DataView_Add)} arrow disableInteractive placement="top">
                            <AddIcon />
                        </Tooltip></LoadingButton>
                    </Stack>
                </>
            case DataViewAction.Edit:
                return <>
                    {withView.filter((col) => col.ViewRenderer.RenderEdit !== undefined).map((col) => col.ViewRenderer.RenderEdit!(col, editorControl, edit.ForceUpdate))}
                    <Stack direction="row" sx={{ justifyContent: 'end' }}>
                        <LoadingButton variant="contained" type="submit" loading={loading}><Tooltip title={GetText(i18nSheet.DataView_Edit)} arrow disableInteractive placement="top">
                            <EditIcon />
                        </Tooltip></LoadingButton>
                    </Stack>
                </>
            case DataViewAction.View:
                return withView.filter((col) => col.ViewRenderer.RenderView !== undefined).map((col) => col.ViewRenderer.RenderView!(edit.Data, col, editorControl, edit.ForceUpdate));
            case DataViewAction.Delete:
                return <Stack direction="row" sx={{ justifyContent: 'end' }}>
                    <LoadingButton variant="contained" type="submit" loading={loading} color="error"><Tooltip title={GetText(i18nSheet.DataView_Delete)} arrow disableInteractive placement="top">
                        <DeleteIcon />
                    </Tooltip></LoadingButton>
                </Stack>;
        }
    }

    const tableColumns = useMemo(() => {
        return props.Columns.filter((column): column is WithRequired<DataViewColumn, 'TableRenderer'> => column.TableRenderer !== undefined);
    }, [props.Columns]);

    const onEditSubmit = async (e: SyntheticEvent) => {
        e.preventDefault();
        if (!edit) { return; }
        if (edit.Action === DataViewAction.View) {
            return;
        }

        if (edit.Action !== DataViewAction.Delete && newData === undefined) {
            return;
        }
        let success = false;
        setLoading(true);
        if (edit.Action === DataViewAction.Add && props.AddData !== undefined) {
            const res = await props.AddData(newData);
            if (res.Error) {
                if (res.ErrorParams?.Field) {
                    editorControl.SetError(res.ErrorParams.Field, res.Error, res.ErrorParams);
                } else {
                    SnackError(res.Error, res.ErrorParams?.Params);
                }
            } else {
                setNewData(undefined);
            }
            success = res?.Success ?? false;
        } else if (edit.Action === DataViewAction.Edit && props.ModifyData !== undefined) {
            const modifiedData: any = {};
            const editableCols = props.Columns.filter((column): column is WithRequired<DataViewColumn, 'ViewRenderer'> => column.ViewRenderer !== undefined && column.ViewRenderer.RenderEdit !== undefined);
            editableCols.forEach((col) => {
                if (newData === undefined || newData[col.Field] === undefined) { return }
                if (col.ViewRenderer.Equals === undefined || !col.ViewRenderer.Equals(edit.Data[col.Field], newData[col.Field])) {
                    modifiedData[col.Field] = newData[col.Field];
                }
            });
            if (Object.keys(modifiedData).length === 0) {
                success = true;
            } else {
                const res = await props.ModifyData(edit.Data, modifiedData);
                if (res.Error) {
                    if (res.ErrorParams?.Field) {
                        editorControl.SetError(res.ErrorParams.Field, res.Error, res.ErrorParams);
                    } else {
                        SnackError(res.Error, res.ErrorParams?.Params);
                    }
                }
                success = res?.Success ?? false;
            }
        } else if (edit.Action === DataViewAction.Delete && props.DeleteData !== undefined) {
            const res = await props.DeleteData(edit.Data);
            success = res?.Success ?? false;
            if (res.Error) {
                SnackError(res.Error, res.ErrorParams?.Params);
            }
        }
        setLoading(false);
        if (success) {
            setReFetch({});
            editorControl.CloseEdit();
        }
    }

    const saveAll = async () => {
        if (!hasEditPermission || !props.InTableEdit) {
            return;
        }
        const newData = { ...newDataInTable };
        if (data === undefined || newData === undefined || Object.keys(newData).length === 0) {
            return;
        }
        setLoading(true);
        for (const row of data) {
            if (newData[row[props.DataIdField]] === undefined) {
                continue;
            } try {
                const res = await props.ModifyData(row, newData[row[props.DataIdField]]);
                if (res.Success === true) {
                    delete newData[row[props.DataIdField]];
                }
            } catch { //
            }
        }
        try {
            setReFetch({});
        } catch { //
        }
        setLoading(false);
    }

    const onFetch = async (queries: any, forcePage?: number) => {
        setLoading(true);
        try {
            const actualPage = forcePage ?? page;
            const res: any = await props.QueryFunction(queries, { Page: (actualPage - 1).toString(), PageSize: pageSize.toString() });
            setData(undefined);
            setRawResponse(undefined);
            setNewDataInTable(undefined);
            if (Array.isArray(res)) {
                res.forEach((item) => { item._ref = createRef() });
                setData(res as unknown[]);
            } else if (res instanceof Object && Array.isArray(res?.Data)) {
                res.Data.forEach((item: any) => { item._ref = createRef() });
                setData(res.Data);
                setTotalPage(Math.ceil((res?.Page?.Count ?? 0) / pageSize))
                setPage(actualPage);
            }
            setRawResponse(res);
        } catch {
            SnackError(i18nSheet.Error_Unknown);
        } finally {
            setLoading(false);
        }
    }

    const editorSize = edit?.Action === DataViewAction.Delete ? DataViewEditorSize.Small :
        edit?.Action === DataViewAction.Custom ? edit.Size : DataViewEditorSize.Normal;

    const additionalBlock = props.AdditionalBlock?.(rawResponse);

    return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: 2, padding: 2, position: 'relative', width: '100%', boxSizing: 'border-box', overflow: 'hidden' }}>
        {loading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} />}

        <Box sx={{ display: 'flex' }}>
            <QueryBox Queries={props.Queries} OnFetch={onFetch} Loading={loading} AutoLoading={props.AutoLoading} ReFetch={reFetch} />
            {hasEditPermission && props.AddData ? <Button onClick={editorControl.OpenAdd}><Tooltip title={GetText(i18nSheet.DataView_Add)} arrow disableInteractive placement="top">
                <AddIcon />
            </Tooltip></Button> : <div></div>}
        </Box>

        {additionalBlock ? <Card sx={{ padding: 2, flexShrink: 0, maxHeight: '30%' }}>
            {additionalBlock()}
        </Card> : null}
        <Card sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{ overflow: 'auto', flexGrow: 1 }}>
                <Table size="small">
                    <TableBody>
                        {data?.map((row: any, index) => <CSSTransition
                            key={props.EditorTitleColumn ? row[props.EditorTitleColumn] : index}
                            timeout={{ enter: 150, exit: 150 }}
                            nodeRef={row._ref}
                            classNames="dataView_item">
                            <TableRow ref={row._ref} sx={{
                                '&:hover': {
                                    backgroundColor: (theme) => {
                                        if (props.InTableEdit && newDataInTable?.[row[props.DataIdField]] !== undefined) {
                                            return 'rgba(255, 167, 38, 0.25)';
                                        }
                                        return theme.palette.action.hover;
                                    }
                                },
                                backgroundColor: (theme) => {
                                    if (!props.InTableEdit) {
                                        return undefined;
                                    }
                                    if (newDataInTable?.[row[props.DataIdField]] !== undefined) {
                                        return 'rgba(245, 124, 0, 0.25)';
                                    }
                                }
                            }}>
                                {tableColumns.map((column, index) => {
                                    return <TableCell key={`${page}.${index}`}>{column.TableRenderer(row, column, control)}</TableCell>
                                })}
                            </TableRow>
                        </CSSTransition>
                        ) ?? <></>}
                    </TableBody>
                    <TableHead sx={{ position: 'sticky', top: 0, backgroundColor: (theme) => theme.palette.background.paper }}>
                        <TableRow>
                            {tableColumns.map((column, index) => {
                                return <TableCell key={index}>
                                    {GetText(column.Name)}
                                    {index === tableColumns.length - 1 && props.InTableEdit && hasEditPermission ? <IconButton disabled={loading || !newDataInTable || Object.keys(newDataInTable).length === 0} size="small" onClick={saveAll}><Tooltip title={GetText(i18nSheet.DataView_SaveAll)} arrow disableInteractive placement="top"><SaveIcon fontSize="small" /></Tooltip></IconButton> : null}
                                </TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                </Table>
            </Box>
            {
                totalPage > 0 ?
                    <Box sx={{ display: 'flex', justifyContent: 'center', p: 1 }}>
                        <Pagination disabled={loading} count={totalPage} page={page} shape="rounded" onChange={async (_, value) => {
                            setPage(value);
                            setSearchParams(param => { param.set('PageIndex', value.toString()); return param; });
                            setReFetch({});
                        }} />
                    </Box>
                    : null
            }
        </Card >
        <Paper className={editClassName} sx={{
            width: '100%', height: '100%', position: 'absolute', top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', perspectiveOrigin: 'center', perspective: '1000px', background: 'unset'
        }} onClick={edit?.Action === DataViewAction.View ? (e) => { if (e.currentTarget !== e.target) { return; } editorControl.CloseEdit(); } : undefined}>
            {<Card className="editorCard" sx={{
                borderRadius: '9px', display: 'flex', flexDirection: 'column', position: 'relative', maxWidth: '480px', width: '80%', maxHeight: '75%', minHeight: editorSize === DataViewEditorSize.Small ? null : '50%', overflow: 'hidden', background: 'rgb(51 51 56 / 40%)', backdropFilter: 'blur(10px)', boxShadow: 'inset rgb(4 4 4 / 90%) 0px 0px 0px 2px, inset rgb(110 110 110 / 10%) 0px 0px 0px 4px'
            }}>
                {edit && (edit.Action !== DataViewAction.Custom ? <><IconButton sx={{ position: 'absolute', right: 0, top: 0, borderRadius: '0 0 0 4px', '&:hover': { background: (theme) => theme.palette.error.dark } }} onClick={() => editorControl.CloseEdit()} disabled={loading}><CloseIcon /></IconButton>
                    <Box sx={{ background: 'rgb(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center' }}>
                        <Typography variant="h6" sx={{ my: 1 }}>{GetActionText(edit, props)}</Typography>
                    </Box>
                    <Divider />
                    <form onSubmit={onEditSubmit} style={{ overflow: 'auto' }}>
                        <Stack sx={{ display: "flex", margin: 2, gap: 2, overflow: 'auto' }}>
                            {editRowRenderer()}
                        </Stack>
                    </form>
                </> : edit.Renderer(edit.Data, editorControl, edit.ForceUpdate))}
            </Card>}
        </Paper>
    </Box >;
}

function GetActionText(edit: DataViewEditProps, props: DataViewProps): string {
    const tableTitle = props.DataTitle ? GetText(props.DataTitle) : '';
    switch (edit.Action) {
        case DataViewAction.Add:
            return GetText(i18nSheet.DataView_AddWithTitle, tableTitle);
        case DataViewAction.Edit:
            return GetText(i18nSheet.DataView_EditWithTitle, tableTitle);
        case DataViewAction.View:
            return GetText(i18nSheet.DataView_DetailWithTitle, tableTitle);
        case DataViewAction.Delete:
            if (props.EditorTitleColumn) {
                return GetText(i18nSheet.DataView_DeleteWithTitleAndName, tableTitle, edit.Data[props.EditorTitleColumn]);
            }
            return GetText(i18nSheet.DataView_DeleteWithTitle, tableTitle);
        default:
            return '';
    }
}

