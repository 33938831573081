import TextField from "@mui/material/TextField";
import { i18n } from "../../../dataSheets/generated/i18n";
import { GetText } from "../../../dataSheets/i18n";
import { ColumnEditor, DataViewColumn, DataViewEditorControl } from "../../../model/DataView";

type TextColumnViewerOptions = {
    Multiline?: boolean;
    MinRows?: number;
    MaxRows?: number;
    HideInEditMode?: boolean
    HideInViewMode?: boolean
}

export default class TextColumnViewer implements ColumnEditor {
    constructor(private label: i18n, private options?: TextColumnViewerOptions) {
    }

    RenderView(data: any, col: DataViewColumn, controls: DataViewEditorControl) {
        if (this.options?.HideInViewMode) {
            return null;
        }
        return <TextField key={col.Name.Id} variant="filled" multiline={this.options?.Multiline} minRows={this.options?.MinRows} maxRows={this.options?.MaxRows} label={GetText(this.label)} value={data[col.Field]}></TextField>
    }

    RenderAdd(col: DataViewColumn, controls: DataViewEditorControl) {
        return this.renderEdit(col, controls)

    }

    RenderEdit(col: DataViewColumn, controls: DataViewEditorControl) {
        if (this.options?.HideInEditMode) {
            return null;
        }
        return this.renderEdit(col, controls)
    }

    renderEdit(col: DataViewColumn, controls: DataViewEditorControl) {
        const error = controls.GetError(col.Field);
        return <TextField key={col.Name.Id} error={error !== undefined} helperText={error ? GetText(error.Error, error.ErrorParams.ErrorParams) : undefined} variant="filled" autoComplete="off" multiline={this.options?.Multiline} minRows={this.options?.MinRows} maxRows={this.options?.MaxRows} label={GetText(this.label)} value={controls.GetEditValue(col.Field) ?? ''} onChange={(e) => {
            if (controls.GetEditValue(col.Field) === undefined && e.target.value === '') {
                controls.SetNewData(col.Field, undefined);
            } else {
                controls.SetNewData(col.Field, e.target.value)
            }
        }}></TextField>
    }

    Equals(data1: string, data2: string): boolean {
        return data1 === data2;
    }
}
