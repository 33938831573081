import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { useSignals } from "@preact/signals-react/runtime";
import { ChangeEvent, FocusEvent, KeyboardEvent, MouseEvent as ReactMouseEvent, useEffect, useRef, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import { i18n, i18nSheet } from "../../../dataSheets/generated/i18n";
import { GetText } from "../../../dataSheets/i18n";
import { UseRequestAnimationFrame } from '../../../Utils/animation';

export type QueryProps = {
    Label: i18n;
    Field: string;
    Disabled?: boolean;
}

export type QueryDropRendererProps = {
    DropEvents: DropEvents;
    SetQueryValue: (value: string, display?: () => string) => void;
    GetQueryValue: () => string | undefined;
}

export abstract class Query {
    Label: i18n;
    Field: string;
    Disabled: boolean;

    constructor(props: QueryProps) {
        this.Label = props.Label;
        this.Field = props.Field;
        this.Disabled = !!props.Disabled;
    }

    abstract DropRenderer(props: QueryDropRendererProps): JSX.Element | null;
    abstract OnInputBlur(value: string): { newValue: string, display?: () => string } | null;
    GetDisplay?(value: string): Promise<(() => string) | null>;
}

export type QueryBoxProps = {
    Queries: Query[];
    OnFetch: (queries: { [key: string]: string }, page?: number) => Promise<unknown>;
    AutoLoading?: boolean;
    Loading: boolean;
    ReFetch?: any;
}

type DropEvents = {
    OnKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
    OnInputChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

type QueryState = {
    Query: Query;
    Value: string;
    Display?: () => string;
    Loading?: boolean;
}

export function QueryBox(props: QueryBoxProps) {
    useSignals();
    const filteredQueries = props.Queries.filter((query) => !query.Disabled);
    const [searchParams, setSearchParams] = useSearchParams();

    const [showDrop, setShowDrop] = useState<boolean>(false);
    const [currentQuery, setCurrentQuery] = useState<Query | null>(null);
    const [queries, setQueries] = useState<QueryState[]>([]);

    const [dropSelectedIndex, setDropSelectedIndex] = useState<number>(-1);
    const [preventBlur, setPreventBlur] = useState(false);
    const [isSelfLoading, setIsSelfLoading] = useState<boolean>(false);
    const isLoading = props.Loading || isSelfLoading;

    const rootRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const dropRef = useRef<HTMLDivElement>(null);
    const queryInputRef = useRef<HTMLInputElement>(null);
    const dropEvent: DropEvents = {};

    const unSelectedQueries = filteredQueries.filter((query) => queries.findIndex((q) => q.Query === query) === -1);
    const [init] = useState(props.ReFetch);

    UseRequestAnimationFrame(() => {
        if (showDrop) {
            if (!rootRef.current) {
                return;
            }
            const rootLeft = rootRef.current.getBoundingClientRect().left ?? 0;
            const rootWidth = rootRef.current.getBoundingClientRect().width;
            const input = currentQuery ? queryInputRef.current : inputRef.current;
            if (!input || !dropRef.current) {
                return;
            }
            const inputLeft = input.getBoundingClientRect().left;
            let dropPosition = inputLeft - rootLeft;
            if (dropPosition < 0) {
                dropPosition = 0;
            }
            const dropWidth = dropRef.current.getBoundingClientRect().width;
            if (dropPosition + dropWidth > rootWidth) {
                dropPosition = rootWidth - dropWidth;
                if (dropPosition < 0) {
                    dropPosition = 0;
                }
            }
            dropRef.current.style.setProperty('--drop-position', `${dropPosition}px`);
        } else {
            if (!dropRef.current) {
                return;
            }
            dropRef.current.style.setProperty('--drop-position', `${0}px`);
        }
    })

    useEffect(() => {
        if (init === props.ReFetch) {
            const queries: QueryState[] = []
            const promises: Promise<void>[] = [];
            setIsSelfLoading(true);
            filteredQueries.forEach((query) => {
                const value = searchParams.get(query.Field);
                if (value === null) {
                    return;
                }
                if (query.GetDisplay) {
                    promises.push(query.GetDisplay(value).then((display) => {
                        setTimeout(() => {
                            if (display === null) {
                                setQueries((queries) => queries.filter((q) => q.Query !== query));
                            } else {
                                setQueries((queries) => queries.map((q) => {
                                    if (q.Query === query) {
                                        return { Query: q.Query, Value: q.Value, Display: display };
                                    }
                                    return q;
                                }));
                            }
                        });
                    }))
                    queries.push({ Query: query, Value: value, Loading: true });
                } else {
                    queries.push({ Query: query, Value: value });
                }
            })

            setQueries(queries);
            Promise.all(promises).then(() => {
                setIsSelfLoading(false);
                if (props.AutoLoading || queries.length > 0) {
                    fetchQuery(queries);
                }
            })
        } else {
            fetchQuery(queries);
        }
    }, [props.ReFetch])



    const onInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        setShowDrop(true);
        setCurrentQuery(null);
    }

    const onQueryInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        setShowDrop(true);
    }

    const onInputBlur = (arg1: FocusEvent<HTMLInputElement> | string) => {
        let value = '';
        let e: FocusEvent<HTMLInputElement> | undefined;
        if (typeof arg1 === 'string') {
            value = arg1;
            e = undefined;
        } else {
            e = arg1;
            value = e.currentTarget.value;
        }

        if (inputRef.current) {
            inputRef.current!.value = '';
        }

        if (currentQuery) {
            setShowDrop(true);
            setTimeout(() => {
                queryInputRef.current?.focus();
            });
        } else {
            setShowDrop(false);
        }

        setDropSelectedIndex(-1);
    }

    const onQueryInputBlur = (arg1: FocusEvent<HTMLInputElement> | string) => {
        let value = '';
        let e: FocusEvent<HTMLInputElement> | undefined;
        if (typeof arg1 === 'string') {
            value = arg1;
            e = undefined;
        } else {
            e = arg1;
            value = e.currentTarget.value;
        }

        if (preventBlur) {
            setTimeout(() => {
                if (currentQuery) {
                    queryInputRef.current?.focus();
                }
            })
        } else {
            if (currentQuery) {
                const blurResult = currentQuery.OnInputBlur(value);
                if (blurResult) {
                    const { newValue, display } = blurResult;
                    setQueryValue(newValue, display);
                } else {
                    setQueryValue('')
                }
                if (inputRef.current) {
                    inputRef.current.value = '';
                }
            }
            setCurrentQuery(null);
            setShowDrop(false);
        }
    }

    const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Backspace' && e.currentTarget.value.length === 0) {
            if (queries.length > 0) {
                const lastQuery = queries[queries.length - 1].Query;
                setCurrentQuery(lastQuery);
                const newQueries = [...queries];
                newQueries[queries.length - 1] = { Query: lastQuery, Value: '' };
                setQueries(newQueries);
                setTimeout(() => {
                    queryInputRef.current?.focus();
                })
            }
            e.preventDefault();
        } else if (e.key === 'ArrowUp') {
            setDropSelectedIndex((prev) => {
                if (prev === -1) {
                    return unSelectedQueries.length - 1;
                } else {
                    return prev - 1;
                }
            });
            e.preventDefault();
        } else if (e.key === 'ArrowDown') {
            setDropSelectedIndex((prev) => {
                if (prev === unSelectedQueries.length - 1) {
                    return -1;
                } else {
                    return prev + 1;
                }
            });
            e.preventDefault();
        } else if (e.key === 'Enter') {
            if (dropSelectedIndex !== -1) {
                onDropQueryMouseDown(unSelectedQueries[dropSelectedIndex]);
                e.currentTarget.value = '';
                e.currentTarget.blur();
                setTimeout(() => {
                    queryInputRef.current?.focus();
                });
                e.preventDefault();
            } else {
                e.currentTarget.blur();
                fetchQuery(queries);
                e.preventDefault();
            }
        } else if (e.key === 'Escape') {
            e.currentTarget.blur();
        }
    }

    const onQueryInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        if (dropEvent.OnKeyDown) {
            dropEvent.OnKeyDown(e)
        }
        if (e.key === 'Backspace' && e.currentTarget.value.length === 0) {
            setQueries(queries => queries.filter((query) => query.Query !== currentQuery));
            setCurrentQuery(null);
            setTimeout(() => {
                inputRef.current?.focus();
            });
            e.preventDefault();
        } else if (e.key === 'Enter' || e.key === 'Tab') {
            onQueryInputBlur(e.currentTarget.value);
            setTimeout(() => {
                inputRef.current?.focus();
            })
            e.preventDefault();
        } else if (e.key === 'Escape') {
            e.currentTarget.value = '';
            e.currentTarget.blur();
            setTimeout(() => {
                inputRef.current?.focus();
            })
            e.preventDefault();
        }
    }

    const onDropQueryMouseDown = (query: Query) => {
        setQueries((prev) => [...prev, { Query: query, Value: '' }]);
        setCurrentQuery(query);
    }

    const setQueryValue = (value: string, display?: () => string) => {
        if (!currentQuery) {
            return;
        }
        if (value.length === 0) {
            setQueries(queries => {
                const query = queries.find((query) => query.Query === currentQuery);
                if (!query?.Value) {
                    return queries.filter((query) => query.Query !== currentQuery);
                }
                return queries;
            });

            setCurrentQuery(null);
            return;
        }
        setQueries((prev) => prev.map((query) => {
            if (query.Query === currentQuery) {
                return { Query: query.Query, Value: value, Display: display };
            } else {
                return query;
            }
        }));
        setCurrentQuery(null);
        if (showDrop) {
            setTimeout(() => {
                inputRef.current?.focus();
            })
        }
    }

    const getQueryValue = () => {
        if (currentQuery) {
            return queries.find((query) => query.Query === currentQuery)?.Value ?? '';
        }
    }

    const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (dropEvent.OnInputChange) {
            dropEvent.OnInputChange(e);
        }
    }

    const onDropMouseDown = (e: ReactMouseEvent<HTMLDivElement>) => {
        const onDropMouseUp = (e: MouseEvent) => {
            setPreventBlur(false);
            window.removeEventListener('mouseup', onDropMouseUp);
        }

        setPreventBlur(true);
        window.addEventListener('mouseup', onDropMouseUp);
    }

    const fetchQuery = async (queries: QueryState[], page?: number) => {
        if (isLoading) {
            return;
        }
        const params: Record<string, string> = {};
        queries.forEach((query) => {
            params[query.Query.Field] = query.Value;
        });

        await props.OnFetch(params, page);

        setSearchParams((urlParams) => {
            const p = page?.toString() ?? urlParams.get('PageIndex')
            if (p !== null && p !== '1') {
                params.PageIndex = p
            }
            return new URLSearchParams(params);
        });
    }

    let dropContent: JSX.Element | null = null;

    if (showDrop) {
        if (currentQuery === null) {
            if (unSelectedQueries.length === 0) {
                dropContent = null;
            } else {
                dropContent = <Box
                    sx={{
                        padding: 1,
                    }}>
                    {unSelectedQueries.map((query, index) => {
                        const selected = index === dropSelectedIndex;
                        return <Box key={index} sx={{
                            padding: '0.5rem 1rem',
                            cursor: 'pointer',
                            borderRadius: 2,
                            ':hover': {
                                backgroundColor: (theme) => theme.palette.action.hover
                            },
                            border: selected ? '1px solid rgba(0, 119, 255, 1)' : '1px solid transparent',
                        }} onMouseDown={(e) => {
                            e.stopPropagation();
                            onDropQueryMouseDown(query);
                        }} >
                            {GetText(query.Label)}
                        </Box>
                    })}
                </Box>
            }
        } else {
            dropContent = <currentQuery.DropRenderer SetQueryValue={setQueryValue} DropEvents={dropEvent} GetQueryValue={(getQueryValue)} />;
        }
    }

    return <Box sx={{
        border: '1px solid rgba(255, 255, 255, 0.12)',
        borderRadius: 1,
        position: 'relative',
        padding: 0.5,
        minHeight: '1.5rem',
        alignContent: 'center',
        textWrap: 'nowrap',
        display: 'flex',
        flex: '1 1 0',
        minWidth: '0',
        backgroundColor: (theme) => theme.palette.background.paper,
    }} ref={rootRef}>
        <Box sx={{
            display: 'flex',
            overflow: 'hidden',
            flexGrow: 1,
            width: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                overflowY: 'auto',
                flexGrow: 1,
            }}>
                {queries.map((query, index) => {
                    return <Box key={index} sx={{
                        display: 'inline-flex',
                        pr: 1,
                        lineHeight: '12px',
                        '& span': {
                            height: '1.5rem',
                            boxSizing: 'border-box',
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: (theme) => theme.palette.grey[800],
                            fontSize: '0.875rem',
                            px: '0.5rem',
                        },
                        '&:hover span': {
                            backgroundColor: (theme) => theme.palette.grey[700]
                        }
                    }}>
                        <Box component="span" sx={{ borderRadius: '2px 0 0 2px' }}>{GetText(query.Query.Label)}</Box>
                        <Box component="span" sx={{ mx: '2px' }}>=</Box>
                        {query.Loading ? <Box component="span" sx={{
                            borderRadius: '0 2px 2px 0',
                            overflow: 'hidden',
                            color: (theme) => theme.palette.text.disabled,
                        }}>
                            <CircularProgress size="1.875rem" color="inherit" />
                        </Box> : query.Query !== currentQuery ?
                            <Box component="span" sx={{ borderRadius: '0 2px 2px 0' }}>
                                <Box onClick={() => {
                                    setCurrentQuery(query.Query);
                                    setTimeout(() => {
                                        const queryInput = queryInputRef.current;
                                        if (queryInput) {
                                            queryInput.value = query.Display ? query.Display() : query.Value;
                                            queryInput.select();
                                        }
                                    });
                                }}>
                                    {query.Display ? query.Display() : query.Value}
                                </Box>
                                <Box sx={{
                                    width: '0.875rem',
                                    height: '0.875rem',
                                    ml: 0.5,
                                    cursor: 'pointer',
                                    p: 0.5,
                                    borderRadius: '50%',
                                    ':hover': {
                                        backgroundColor: (theme) => theme.palette.action.selected
                                    }
                                }} onClick={() => {
                                    setQueries((prev) => prev.filter((q) => q !== query));
                                }}><CloseIcon sx={{ fontSize: '0.875rem' }} /></Box>
                            </Box> : <Box component="input"
                                sx={{
                                    all: 'unset',
                                    minWidth: '1rem',
                                    display: 'inline-block',
                                    fontSize: '0.875rem',
                                }}
                                onFocus={onQueryInputFocus}
                                onBlur={onQueryInputBlur}
                                onKeyDown={onQueryInputKeyDown}
                                onChange={inputOnChange}
                                placeholder={queries.length === 0 ? GetText(i18nSheet.DataView_Filter) : undefined}
                                ref={queryInputRef}
                            />}
                    </Box>
                })}
                <Box component="input"
                    sx={{
                        all: 'unset',
                        minWidth: '5rem',
                        display: 'inline-block',
                        fontSize: '0.875rem',
                        flexGrow: 1,
                    }}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    onKeyDown={onInputKeyDown}
                    onChange={inputOnChange}
                    placeholder={queries.length === 0 ? GetText(i18nSheet.DataView_Filter) : undefined}
                    ref={inputRef}
                />
            </Box>
            <Divider orientation='vertical' />
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                color: (theme) => theme.palette.text.disabled,
                borderRadius: '0 2px 2px 0',
                flex: '0 0 1.5rem',
                ':hover': isLoading ? {} : {
                    color: (theme) => theme.palette.text.primary,
                }
            }} onClick={() => { fetchQuery(queries, 1) }}><SearchIcon sx={{ fontSize: '0.875rem' }} /></Box>
        </Box>
        <Box sx={{
            position: 'absolute',
            backgroundColor: (theme) => theme.palette.background.paper,
            visibility: showDrop && dropContent ? 'visible' : 'hidden',
            opacity: showDrop && dropContent ? 1 : 0,
            transform: `translate(var(--drop-position), 5px)`,
            top: '100%',
            left: 0,
            border: '1px solid rgba(255, 255, 255, 0.12)',
            borderRadius: 3,
            transition: 'opacity 0.2s',
            maxWidth: '100%',
            maxHeight: '30rem',
            overflow: 'auto',
            zIndex: 1
        }} ref={dropRef}
            onMouseDown={onDropMouseDown}
        >
            {dropContent}
        </Box>
    </Box >
}