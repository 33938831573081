import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";

const Millisecond = 1;
const Second = 1000 * Millisecond;
const Minute = 60 * Second;
const Hour = 60 * Minute;
const Day = 24 * Hour;


export const Time = {
    Millisecond,
    Second,
    Minute,
    Hour,
    Day
}

dayjs.extend(utc);

export const Dayjs = dayjs;