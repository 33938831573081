import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { TreeViewBaseItem } from '@mui/x-tree-view/models';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { useSignals } from "@preact/signals-react/runtime";
import { useEffect, useMemo, useState } from "react";
import DataView from "../../components/DataView/DataView";
import TextColumnViewer from "../../components/DataView/ViewRenderer/TextColumnViewer";
import { i18n, i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";
import { ColumnEditor, DataViewColumn, DataViewEditorControl } from "../../model/DataView";
import { User } from "../../network/Account";
import { Get, Post } from "../../Utils/net";

const EditPermission = 'EditSiteRole';

let PermissionTree: any = {}

type TreeViewProps = {
    tree: any,
    data: string[],
    expendAll?: boolean,
    getLabel: (key: string) => string,
} & ({
    editable: true,
    setData: (data: string[]) => void
} | {
    editable?: false
})

function TreeView(props: TreeViewProps) {
    const [treeViewItems, childNodes, ancestors] = useMemo(() => {
        const childNodes = new Map<string, string[]>();
        const ancestors = new Map<string, string[]>();
        const treeViewItems = getTreeViewItems(props.tree, childNodes, ancestors, props.getLabel)
        return [treeViewItems, childNodes, ancestors]
    }, [props.tree, props.getLabel]);

    const [selected, setSelected] = useState<Set<string>>(new Set(props.data ?? []));
    const [expanded, setExpanded] = useState<string[]>(props.expendAll ? [...childNodes.keys()] : []);

    useEffect(() => {
        setSelected(new Set(props.data ?? []));
    }, [props.data])

    const selectedDisplay = useMemo(() => {
        const set = new Set<string>();
        new Set<string>();
        const stack = [...selected];
        while (stack.length > 0) {
            const node = stack.pop()!;
            if (set.has(node)) { continue; }
            set.add(node);
            const children = childNodes.get(node) ?? [];
            for (const child of children) {
                stack.push(child);
            }
        }
        return set;
    }, [selected, childNodes])

    const onSelectToggle = (e: React.SyntheticEvent, itemId: string, iSelected: boolean) => {
        if (!props.editable) {
            return;
        }
        const newSelected = new Set(selected);
        if (selectedDisplay.has(itemId)) {
            if (selected.has(itemId)) {
                newSelected.delete(itemId);
            } else {
                const ancestorsList = ancestors.get(itemId) ?? [];
                for (const ancestor of ancestorsList) {
                    if (selected.has(ancestor)) {
                        newSelected.delete(ancestor);
                        for (const child of childNodes.get(ancestor) ?? []) {
                            if (!ancestorsList.includes(child)) {
                                newSelected.add(child);
                            }
                        }
                    }
                }
            }
        } else {
            newSelected.add(itemId);
            const stack = [...childNodes.get(itemId) ?? []];
            while (stack.length > 0) {
                const node = stack.pop()!;
                newSelected.delete(node);
                const children = childNodes.get(node) ?? [];
                for (const child of children) {
                    stack.push(child);
                }
            }
        }
        props.setData([...newSelected]);
        setSelected(newSelected);
    }

    return <RichTreeView items={treeViewItems} expandedItems={expanded} onExpandedItemsChange={(e, items) => { setExpanded(items) }} selectedItems={[...selectedDisplay]} multiSelect checkboxSelection onItemSelectionToggle={onSelectToggle} />
}

function getTreeViewItems(permission: any, childNodes: Map<string, string[]>, ancestors: Map<string, string[]>, getLabel: (key: string) => string): TreeViewBaseItem[] {
    const entries = Object.entries(permission);
    if (entries.length === 0) {
        return [];
    }

    return entries.map(([key, value]) => {
        childNodes.set(key, Object.keys(value as any));
        const ancestor = ancestors.get(key) ?? []
        for (const child of Object.keys(value as any)) {
            ancestors.set(child, [...ancestor, key]);
        }
        return {
            id: key,
            label: getLabel(key),
            children: getTreeViewItems(value, childNodes, ancestors, getLabel)
        }
    });
}

class PermissionColumnViewer implements ColumnEditor {
    constructor(private label: i18n) {
    }

    RenderView(data: any, col: DataViewColumn, controls: DataViewEditorControl) {
        return <TreeView tree={PermissionTree} data={data[col.Field]} expendAll getLabel={key => GetText(`Privilege_${key}`)} />
    }

    RenderAdd(col: DataViewColumn, controls: DataViewEditorControl) {
        return this.RenderEdit(col, controls);
    }

    RenderEdit(col: DataViewColumn, controls: DataViewEditorControl) {
        return <TreeView tree={PermissionTree} data={controls.GetEditValue(col.Field) ?? []} expendAll editable setData={(data) => controls.SetNewData(col.Field, data)} getLabel={key => GetText(`Privilege_${key}`)} />
    }

    Equals(data1: string[], data2: string[]): boolean {
        if (data1 === data2) {
            return true;
        }
        if (data1 === undefined || data2 === undefined) {
            return false;
        }
        if (data1.length !== data2.length) {
            return false;
        }
        for (const item of data1) {
            if (!data2.includes(item)) {
                return false;
            }
        }
        return true;
    }
}

export default function Role() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.Role_Name,
            Field: 'Name',
            TableRenderer: (data, col) => <>{data[col.Field]}</>,
            ViewRenderer: new TextColumnViewer(i18nSheet.Role_Name)
        },
        {
            Name: i18nSheet.Role_Permissions,
            Field: 'Permissions',
            TableRenderer: (data) => {
                if ((data?.Permissions?.length ?? 0) === 0) {
                    return <Chip size="small" label={GetText(i18nSheet.Role_NoPermission)} />
                }

                return <Box sx={{ display: "flex" }}>
                    <Chip size="small" color="primary" sx={{ mr: 1 }} label={GetText(`Privilege_${data.Permissions[0]}`)} />
                    {data.Permissions.length === 2 ? <Chip size="small" color="primary" label={GetText(`Privilege_${data.Permissions[1]}`)} /> : null}
                    {data.Permissions.length > 2 ? <Chip size="small" label={GetText(i18nSheet.DataView_MoreWithNumber, data.Permissions.length - 1)} /> : null}
                </Box>

            },
            ViewRenderer: new PermissionColumnViewer(i18nSheet.Role_Permissions)
        },
        {
            Name: i18nSheet.DataView_Action,
            Field: '',
            TableRenderer: (data, col, control) => <>
                {User.value?.Permissions.includes('EditSiteRole') === true ? <>
                    {User.value?.RoleId !== data.Id && data.Id !== 1 ? <>
                        <IconButton size="small" onClick={() => { control.Editor.OpenEdit(data) }}><Tooltip title={GetText(i18nSheet.DataView_Edit)} arrow disableInteractive placement="top"><EditIcon fontSize="small" /></Tooltip></IconButton>
                        <IconButton size="small" onClick={() => { control.Editor.OpenDelete(data) }}><Tooltip title={GetText(i18nSheet.DataView_Delete)} arrow disableInteractive placement="top"><DeleteIcon fontSize="small" /></Tooltip></IconButton>
                    </> :
                        <IconButton size="small" onClick={() => { control.Editor.OpenView(data) }}><Tooltip title={GetText(i18nSheet.DataView_Detail)} arrow disableInteractive placement="top"><PreviewIcon fontSize="small" /></Tooltip></IconButton>}
                </>
                    : <IconButton size="small" onClick={() => { control.Editor.OpenView(data) }}><Tooltip title={GetText(i18nSheet.DataView_Detail)} arrow disableInteractive placement="top"><PreviewIcon fontSize="small" /></Tooltip></IconButton>}
            </>
        }
    ]

    return <DataView Queries={[]} AutoLoading QueryFunction={async () => {
        const res = await Get('/api/role/roles');
        PermissionTree = res.PermissionTree;
        return res.Roles;
    }} Columns={columns} DataTitle={i18nSheet.Account_Role} EditPermission={EditPermission} AddData={async (data) => {
        const res = await Post('/api/role/addRole', data);
        return res;
    }} ModifyData={async (row, data) => {
        const res = await Post('/api/role/modifyRole', {
            RoleId: row.Id,
            ...data
        });
        return res;
    }} DeleteData={async (row) => {
        const res = await Post('/api/role/deleteRole', {
            RoleId: row.Id
        });
        return res;
    }} EditorTitleColumn="Name"

    />
}