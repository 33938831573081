import Input from "@mui/material/Input";
import { type InputBaseComponentProps } from "@mui/material/InputBase";
import { type SxProps } from "@mui/material/styles";
import { DataViewColumn, DataViewRowControl } from "../../../model/DataView";

type NumberTableRendererProps = {
    controls: DataViewRowControl;
    editable?: boolean;
    data: any,
    col: DataViewColumn
    sx?: SxProps
    inputProps?: InputBaseComponentProps
}

export function NumberTableRenderer(props: NumberTableRendererProps) {
    const value = props.controls.GetNewValue(props.data, props.col.Field) ?? props.data[props.col.Field];

    const error = props.data[props.col.Field] === undefined;
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.controls.SetNewValue(props.data, props.col.Field, parseFloat((event.target as HTMLInputElement).value));
    }

    if (error) {
        return <Input sx={props.sx} size="small" value="" disabled inputProps={{ ...props.inputProps, type: 'number' }} />
    } else if (props.editable && props.controls.HasEditPermission) {
        return <Input sx={props.sx} size="small" value={value} onChange={handleChange} inputProps={{ ...props.inputProps, type: 'number' }} />
    } else {
        return <Input sx={props.sx} size="small" value={value} inputProps={{ ...props.inputProps, type: 'number' }} />
    }
} 