import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useSignals } from '@preact/signals-react/runtime';
import { useRef, useState } from 'react';
import softStarLogo from '../assets/softStarLogo.png';
import { SnackError } from '../components/Snackbar';
import { i18nSheet } from '../dataSheets/generated/i18n';
import { CurrentLanguage, GetText, Language, SetLanguage } from '../dataSheets/i18n';
import { ChangePassword, ForceChangePassword, Login, RemoveToken } from '../network/Account';

function LoginView() {
    useSignals();

    const accountRef = useRef<HTMLInputElement>(null);
    const passwordRef = useRef<HTMLInputElement>(null);
    const newPasswordRef = useRef<HTMLInputElement>(null);
    const confirmPasswordRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    return <Paper elevation={1} sx={{
        display: 'flex',
        padding: 3,
        flexDirection: 'column',
        position: 'relative',
        overflow: 'visible',
        justifyContent: 'space-between',
        width: '300px',
    }}>
        <Card raised sx={{
            background: `url(${softStarLogo}) 50% 50% / 75% no-repeat #ffffffcc`,
            width: '125px',
            height: '125px',
            position: 'absolute',
            top: '-75px',
            alignSelf: 'center',
            borderRadius: '50%',
            backdropFilter: 'blur(10px)',
        }} />
        {ForceChangePassword.value ? <Paper elevation={3} sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '50px',
            mb: 2
        }}>
            <form onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                if (!newPasswordRef.current || !confirmPasswordRef.current) {
                    setLoading(false);
                    return;
                }
                if (newPasswordRef.current.value === '') {
                    SnackError(i18nSheet.Login_NewPasswordEmpty);
                    setLoading(false);
                    return;
                }
                if (newPasswordRef.current.value !== confirmPasswordRef.current.value) {
                    SnackError(i18nSheet.Login_PasswordNotMatch);
                    setLoading(false);
                    return;
                }
                if (await ChangePassword('', newPasswordRef.current.value)) {
                    SnackError(i18nSheet.Login_ChangePasswordSuccess);
                }
                setLoading(false);
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 2
                }}>
                    <TextField label={GetText(i18nSheet.Login_NewPassword)} type="password" autoComplete="new-password" inputRef={newPasswordRef} />
                    <TextField label={GetText(i18nSheet.Login_NewPasswordConfirm)} type="password" autoComplete="new-password" inputRef={confirmPasswordRef} />
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: 2
                    }}>
                        <Button variant="contained" onClick={() => {
                            ForceChangePassword.value = false;
                            RemoveToken();
                        }}>{GetText(i18nSheet.Global_Cancel)}</Button>

                        <LoadingButton loading={loading} variant="contained" type="submit">{GetText(i18nSheet.Login_ChangePassword)}</LoadingButton>
                    </Box>
                </Box>
            </form>
        </Paper> : <><Paper elevation={3} sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: '50px',
            mb: 2
        }}>
            <form onSubmit={async (e) => {
                e.preventDefault();
                setLoading(true);
                if (!accountRef.current || !passwordRef.current) {
                    setLoading(false);
                    return;
                }
                const res = await Login(accountRef.current.value, passwordRef.current.value);
                    if (res.Success === false) {
                    SnackError(res.Error);
                }
                setLoading(false);
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    padding: 2
                }}>
                    <TextField label={GetText(i18nSheet.Login_Account)} autoComplete="username" inputRef={accountRef} />
                    <TextField label={GetText(i18nSheet.Login_Password)} type="password" autoComplete="password" inputRef={passwordRef} />
                    <LoadingButton loading={loading} variant="contained" type="submit">{GetText(i18nSheet.Login_Login)}</LoadingButton>
                </Box>
            </form>
        </Paper>
            <FormControl sx={{ alignSelf: 'end' }} size="small">
                <InputLabel>{GetText(i18nSheet.Login_Language)}</InputLabel>
                <Select
                    value={CurrentLanguage.value}
                    label={GetText(i18nSheet.Login_Language)}
                    onChange={(e) => {
                        SetLanguage(e.target.value as Language);
                    }}>
                    <MenuItem value="en">English</MenuItem>
                    <MenuItem value="zh_TW">繁體中文</MenuItem>
                </Select>
            </FormControl>
        </>
        }

    </Paper >

}

export default LoginView