import Box from "@mui/material/Box";
import { useSignals } from "@preact/signals-react/runtime";
import DataView from "../../components/DataView/DataView";
import DateTimeQuery, { DateTimeQueryType } from "../../components/DataView/Query/DateTimeQuery";
import EnumQuery from "../../components/DataView/Query/EnumQuery";
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";
import { User } from "../../network/Account";
import { GetGameIds } from "../../network/Game";
import { GetOperators } from "../../network/Operator";
import { Get } from "../../Utils/net";

export default function IncomeReport() {
    useSignals();

    return <DataView
        Queries={[
            new EnumQuery({ Label: i18nSheet.Account_Operator, Field: 'OperatorId', EnumValues: GetOperators, Disabled: !User.value?.IsSiteOperator }),
            new EnumQuery({
                Label: i18nSheet.Game_Name, Field: 'GameId', EnumValues: async () => {
                    const res = await GetGameIds();
                    return res.map((x) => [x, x]);
                }
            }),
            new DateTimeQuery({ Label: i18nSheet.DataView_StartTime, Field: 'StartTime', Type: DateTimeQueryType.Date }),
            new DateTimeQuery({ Label: i18nSheet.DataView_EndTime, Field: 'EndTime', Type: DateTimeQueryType.Date }),

            // {
            //     Name: i18nSheet.DataView_None,
            //     Field: '',
            //     Type: QueryDataType.Custom,
            //     Renderer: (props) => {
            //         const bet = props.ResponseData?.Summary?.TotalBetAmount;
            //         const win = props.ResponseData?.Summary?.TotalWinAmount;

            //         if (typeof bet !== 'number' || typeof win !== 'number') {
            //             return null;
            //         }

            //         const income = bet - win;

            //         return <Box>
            //             {GetText(i18nSheet.IncomeReport_TotalIncome)}{bet.toLocaleString()} - {win.toLocaleString()} =
            //             <Box component="span" sx={{
            //                 backgroundColor: (theme) => {
            //                     return income < 0 ? theme.palette.error.dark : theme.palette.primary.dark
            //                 },
            //                 ml: 1,
            //                 padding: 1,
            //                 borderRadius: 2,
            //             }}>{income.toLocaleString()}</Box>
            //         </Box>
            //     }
            // }
        ]}
        AdditionalBlock={(rawResponse) => {
            const bet = rawResponse?.Summary?.TotalBetAmount;
            const win = rawResponse?.Summary?.TotalWinAmount;

            if (typeof bet !== 'number' || typeof win !== 'number') {
                return null;
            }

            const income = bet - win;

            return () => <Box>
                {GetText(i18nSheet.IncomeReport_TotalIncome)}{bet.toLocaleString()} - {win.toLocaleString()} =
                <Box component="span" sx={{
                    backgroundColor: (theme) => {
                        return income < 0 ? theme.palette.error.dark : theme.palette.primary.dark
                    },
                    ml: 1,
                    padding: 1,
                    borderRadius: 2,
                }}>{income.toLocaleString()}</Box>
            </Box>
            return null;
        }}
        QueryFunction={async (queries, page) => {
            const req: Record<string, string> = { ...page }
            if (queries.OperatorId) {
                req.OperatorId = queries.OperatorId;
            }
            if (queries.GameId) {
                req.GameId = queries.GameId;
            }
            if (queries.StartTime) {
                const startTime = parseInt(queries.StartTime);
                if (!isNaN(startTime)) {
                    req.StartTime = startTime.toString();
                }
            }
            if (queries.EndTime) {
                const endTime = parseInt(queries.EndTime);
                if (!isNaN(endTime)) {
                    req.EndTime = endTime.toString();
                }
            }
            return await Get("/api/income/get", req);
        }}
        Columns={[
            {
                Name: i18nSheet.IncomeReport_Date,
                Field: 'Day',
                TableRenderer: (data, column) => {
                    const date = new Date(Number(data[column.Field]) * 1000)
                    return <>{date.toLocaleDateString()}</>
                }
            },
            {
                Name: i18nSheet.Account_Operator,
                Field: 'OperatorName',
                TableRenderer: User.value?.IsSiteOperator ? (data, column) => <> {data[column.Field]}</> : undefined
            },
            {
                Name: i18nSheet.Game_Name,
                Field: 'GameId',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.IncomeReport_PlayerCount,
                Field: 'TotalUser',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.IncomeReport_PlayCount,
                Field: 'TotalPlay',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.BetRecord_BetAmount,
                Field: 'TotalBetAmount',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.BetRecord_WinAmount,
                Field: 'TotalWinAmount',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.Global_RTP,
                Field: '',
                TableRenderer: (data) => {
                    if (data.TotalBetAmount === 0) {
                        return <>-</>
                    }
                    return <>{(data.TotalWinAmount / data.TotalBetAmount).toFixed(2)}</>
                }
            },
            {
                Name: i18nSheet.IncomeReport_FreeGameCount,
                Field: 'FreeGameCount',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.IncomeReport_FreeGameWin,
                Field: 'FreeGameWinAmount',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.IncomeReport_BonusGameCount,
                Field: 'BonusGameCount',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            },
            {
                Name: i18nSheet.IncomeReport_BonusGameWin,
                Field: 'BonusGameWinAmount',
                TableRenderer: (data, column) => <>{data[column.Field]}</>
            }
        ]}
    ></DataView >
}