import EditIcon from '@mui/icons-material/Edit';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { Post, UseGet } from "../../Utils/net";
import { SnackError } from '../../components/Snackbar';
import { i18n, i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";


type SiteSettingBlock = {
    Title: i18n,
    Children: SiteSetting[]
}

enum SiteSettingType {
    Text
}

type SiteSetting = {
    Label: i18n,
    Field: string,
} & (SiteSettingText)

type SiteSettingText = { type: SiteSettingType.Text, props?: TextFieldProps }

const SiteSettings: SiteSettingBlock[] = [
    {
        Title: i18nSheet.SiteSettings_ArcadeServer,
        Children: [{
            Label: i18nSheet.SiteSettings_ApiUrl,
            Field: 'ArcadeApiUrl',
            type: SiteSettingType.Text,
            props: { sx: { width: '100%' } }
        },
        ]
    },
]

export default function Site() {
    const { isLoading: fetching, data, reload } = UseGet('/api/settings/settings');
    const [error, setError] = useState<Record<string, { Error: string, ErrorParams: any }>>({});
    const [newData, setNewData] = useState<Record<number, Record<string, string>>>({});
    const [submitting, setSubmitting] = useState(false);
    const loading = fetching || submitting;

    const getData = (blockIndex: number, field: string) => {
        return newData[blockIndex]?.[field] ?? data?.[field];
    }

    const setData = (blockIndex: number, field: string, value: string) => {
        if (value !== data?.[field]) {
            setNewData({ ...newData, [blockIndex]: { ...newData[blockIndex], [field]: value } });
            if (error[field]) {
                const _error = { ...error };
                delete _error[field];
                setError(_error);
            }
            return;
        }
        const _newData = { ...newData };
        delete _newData[blockIndex]?.[field];
        if (Object.keys(_newData[blockIndex]).length === 0) {
            delete _newData[blockIndex];
        }
        setNewData(_newData);
    }

    const submit = async (blockIndex: number) => {
        setSubmitting(true);
        const req = { ...newData[blockIndex] };
        if (Object.keys(req).length === 0) {
            setSubmitting(false);
            return;
        }

        const res = await Post('/api/settings/modifySettings', req);
        if (res.Error) {
            if (res.ErrorParams?.Field) {
                setError((errorData: any) => { return { ...errorData, [res.ErrorParams.Field]: { Error: res.Error, ErrorParams: res.ErrorParams } } })
            } else {
                SnackError(res.Error, res.ErrorParams?.Params);
            }
        } else {
            reload();
        }

        setSubmitting(false);
    }

    return <Box sx={{ px: 2, position: 'relative', width: '100%', boxSizing: 'border-box', overflow: 'auto' }}>
        {loading && <LinearProgress sx={{ position: 'absolute', top: 0, left: 0, width: '100%' }} />}
        {SiteSettings.map((block, blockIndex) => <Card sx={{ my: 2 }} key={block.Title.Id}>
            <form onSubmit={(e) => { e.preventDefault(); submit(blockIndex) }}>
                <CardHeader title={GetText(block.Title)} />
                <CardContent>
                    <Stack gap="1" alignItems="flex-start">
                        {block.Children.map(setting => {
                            const err = error[setting.Field];
                            switch (setting.type) {
                                case SiteSettingType.Text:
                                    return <TextField key={setting.Label.Id} error={err !== undefined} helperText={err ? GetText(err.Error) : undefined} variant="standard" disabled={loading} label={GetText(setting.Label)} value={getData(blockIndex, setting.Field) ?? ""} onChange={e => { setData(blockIndex, setting.Field, e.target.value) }} {...setting.props} />
                            }
                        })}
                    </Stack>
                </CardContent>
                <CardActions>
                    <LoadingButton variant="contained" type="submit" loading={loading} disabled={newData[blockIndex] === undefined}>
                        <Tooltip title={GetText(i18nSheet.DataView_Edit)} arrow disableInteractive placement="top">
                            <EditIcon />
                        </Tooltip>
                    </LoadingButton>
                </CardActions>
            </form>
        </Card>)}
    </Box>
}