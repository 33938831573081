import AcUnitIcon from '@mui/icons-material/AcUnit';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PreviewIcon from '@mui/icons-material/Preview';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from "@mui/material/IconButton";
import Stack from '@mui/material/Stack';
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import { useSignals } from "@preact/signals-react/runtime";
import { useState } from 'react';
import DataView from "../../components/DataView/DataView";
import EnumQuery from '../../components/DataView/Query/EnumQuery';
import StringQuery from '../../components/DataView/Query/StringQuery';
import SelectColumnViewer from "../../components/DataView/ViewRenderer/SelectColumnViewer";
import TextColumnViewer from "../../components/DataView/ViewRenderer/TextColumnViewer";
import { CustomButton, DeleteButton } from "../../components/IconButton";
import { SnackError } from '../../components/Snackbar';
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { BrowserLanguageMap, CurrentLanguage, GetText } from "../../dataSheets/i18n";
import { DataViewColumn, DataViewEditorControl, DataViewEditorSize, EnumValueType } from "../../model/DataView";
import { User } from "../../network/Account";
import { GetOperators } from "../../network/Operator";
import { GetRoles } from "../../network/Role";
import { Get, Post } from "../../Utils/net";

const Status: EnumValueType[] = [
    ["Active", "Account_Active"],
    ["Freezed", "Account_Freezed"],
    ["Deleted", "Account_Deleted"],
]

const EditPermission = 'EditSiteAccount';

export default function Account() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.Login_Account,
            Field: 'Name',
            TableRenderer: (data, column) => <>{data[column.Field]}</>,
            ViewRenderer: new TextColumnViewer(i18nSheet.Login_Account)
        },
        {
            Name: i18nSheet.Account_Role,
            Field: 'Role',
            TableRenderer: (data, column) => <>{data[column.Field]}</>,
            ViewRenderer: new SelectColumnViewer(i18nSheet.Account_Role, GetRoles, { HideInViewMode: true })
        },
        {
            Name: i18nSheet.Account_Operator,
            Field: 'Operator',
            TableRenderer: (data, column) => <>{data[column.Field]}</>,
            ViewRenderer: new SelectColumnViewer(i18nSheet.Account_Operator, GetOperators, { HideInViewMode: true })
        },
        {
            Name: i18nSheet.Account_Status,
            Field: 'Status',
            TableRenderer: (data, col) => <>{GetText(`Account_${data[col.Field]}`)}</>,
            ViewRenderer: new SelectColumnViewer(i18nSheet.Account_Status, Status, { I18n: true, HideInAddMode: true, HideInEditMode: true })
        },
        {
            Name: i18nSheet.Account_LastLogin,
            Field: 'LastLogin',
            TableRenderer: (data, col) => <>{new Date(data[col.Field]).toLocaleString(BrowserLanguageMap.get(CurrentLanguage.value))}</>,
        }
    ]
    if (User.value?.Permissions.includes("EditSiteAccount")) {
        columns.push({
            Name: i18nSheet.DataView_Action,
            Field: '',
            TableRenderer: (data, col, control) => {
                if ((data?.Status ?? "Deleted") === "Deleted") {
                    return <></>
                } else if (data?.Status === "Freezed") {
                    return <>
                        <CustomButton size={DataViewEditorSize.Small} tooltip={i18nSheet.Account_Unfreeze} icon={AcUnitIcon} data={data} controls={control.Editor} renderer={(data, controls, click) => <Unlock data={data} controls={control.Editor} click={click} />} />
                    </>
                }
                return <>
                    <IconButton size="small" onClick={() => { control.Editor.OpenView(data) }}><Tooltip title={GetText(i18nSheet.DataView_Detail)} arrow disableInteractive placement="top"><PreviewIcon fontSize="small" /></Tooltip></IconButton>
                    <DeleteButton data={data} controls={control.Editor} />
                </>
            }
        });
    }

    return <DataView
        AutoLoading
        Queries={[
            new StringQuery({ Label: i18nSheet.Login_Account, Field: 'Name' }),
            new EnumQuery({ Label: i18nSheet.Account_Role, Field: 'Role', EnumValues: GetRoles }),
            new EnumQuery({ Label: i18nSheet.Account_Operator, Field: 'Operator', EnumValues: GetOperators }),
            new EnumQuery({ Label: i18nSheet.Account_Status, Field: 'Status', EnumValues: Status, I18n: true }),
        ]}
        QueryFunction={async (queries, page) => {
            const req: Record<string, string> = { ...page }

            if (queries.Name) {
                req.Name = queries.Name;
            }
            if (queries.Role) {
                req.Role = queries.Role;
            }
            if (queries.Status) {
                req.Status = queries.Status;
            }
            if (queries.Operator) {
                req.Operator = queries.Operator;
            }
            return await Get("/api/account/getAccounts", req);
        }}
        Columns={columns}
        DataTitle={i18nSheet.Login_Account}
        EditPermission={EditPermission}
        AddData={async (data) => {
            return await Post("/api/account/addAccount", data);
        }}
        EditorTitleColumn="Name"
        DeleteData={async (row) => {
            return await Post("/api/account/deleteAccount", { Name: row.Name });
        }}
    />;
}

type UnlockProps = {
    data: any;
    controls: DataViewEditorControl;
    click: any
}

function Unlock(props: UnlockProps) {
    const [isLoading, setIsLoading] = useState(false);

    const unlock = async () => {
        setIsLoading(true);
        const res = await Post("/api/account/unfreezeAccount", { Name: props.data.Name });
        if (res.Success) {
            props.controls.CloseEdit(true);
        } else {
            SnackError(res.Error);
        }
        setIsLoading(false);
    }

    return <>
        <IconButton sx={{ position: 'absolute', right: 0, top: 0, borderRadius: '0 0 0 4px', '&:hover': { background: (theme) => theme.palette.error.dark } }} onClick={() => props.controls.CloseEdit()} disabled={isLoading}><CloseIcon /></IconButton>
        <Box sx={{ background: 'rgb(0, 0, 0, 0.3)', display: 'flex', justifyContent: 'center' }}>
            <Typography variant="h6" sx={{ my: 1 }}>{GetText(i18nSheet.Account_Unfreeze)}</Typography>
        </Box>
        <Divider />
        <Stack sx={{ display: "flex", margin: 2, gap: 2, overflow: 'auto' }}>
            <Stack direction="row" sx={{ justifyContent: 'end' }}>
                <Button variant="contained" onClick={unlock}><Tooltip title={GetText(i18nSheet.Account_Unfreeze)} arrow disableInteractive placement="top">
                    <CheckIcon />
                </Tooltip></Button>
            </Stack>
        </Stack>
    </>
}