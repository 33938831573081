import { signal } from "@preact/signals-react";
import { redirect } from "react-router-dom";
import { Get, Post } from "../Utils/net";

export const LocalStorageKey_Token = 'token';

export const IsInitLogIn = signal(true);
export const ForceChangePassword = signal(false);
export const LoggedIn = signal(false);

type User = {
    Name: string;
    Permissions: string[];
    RoleId: number;
    IsSiteOperator: boolean;
}

export const User = signal<User | null>();

export async function Login(account: string, password: string) {
    const res = await Post('/api/account/login', { Account: account, Password: password });
    if (res?.Token) {
        window.localStorage.setItem(LocalStorageKey_Token, res.Token);
        if (res.ChangePassword) {
            ForceChangePassword.value = true;
        } else {
            LoggedIn.value = true;
            ForceChangePassword.value = false;
        }
        User.value = {
            Name: res.Name,
            Permissions: res.Permissions,
            RoleId: res.RoleId,
            IsSiteOperator: !!res.IsSiteOperator
        }
    }
    return res;
}

async function loginByToken(): Promise<void> {
    const token = window.localStorage.getItem(LocalStorageKey_Token)
    if (token) {
        const res = await Get('/api/account/loginByToken')
        if (res?.Success) {
            if (res.ChangePassword) {
                ForceChangePassword.value = true;
            } else {
                LoggedIn.value = true;
                ForceChangePassword.value = false;
            }
            User.value = {
                Name: res.Name,
                Permissions: res.Permissions,
                RoleId: res.RoleId,
                IsSiteOperator: !!res.IsSiteOperator
            }
            IsInitLogIn.value = false;
            return;
        }
    }
    window.localStorage.removeItem(LocalStorageKey_Token);
    IsInitLogIn.value = false;
}

export async function Logout() {
    await Get('/api/account/logout');
    window.localStorage.removeItem(LocalStorageKey_Token);
    LoggedIn.value = false;
    redirect('/login');
}

export async function ChangePassword(oldPassword: string, newPassword: string) {
    const res = await Post('/api/account/changePassword', { OldPassword: oldPassword, NewPassword: newPassword });
    if (res?.Success) {
        ForceChangePassword.value = false;
        return true;
    }
    return false;
}

export function RemoveToken() {
    window.localStorage.removeItem(LocalStorageKey_Token);
}

loginByToken();