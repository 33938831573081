import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { CurrentLanguage, GetDateLocale, ToLocals } from "../../../dataSheets/i18n";
import { Dayjs } from '../../../Utils/time';
import { Query, QueryDropRendererProps, QueryProps } from "./QueryBox";

export enum DateTimeQueryType {
    Date,
    Time,
    DateTime
}

export type DateTimeQueryProps = QueryProps & {
    Type: DateTimeQueryType
}

export default class DateTimeQuery extends Query {
    props: DateTimeQueryProps;
    constructor(props: DateTimeQueryProps) {
        super(props);
        this.props = props;
    }

    DropRenderer: (props: QueryDropRendererProps) => JSX.Element = (props) => {
        const initValue = props.GetQueryValue();
        const value = initValue ? Dayjs.unix(parseInt(initValue)) : null;

        switch (this.props.Type) {
            case DateTimeQueryType.Date:
                return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ToLocals(CurrentLanguage.value)}>
                    <StaticDatePicker
                        slotProps={{
                            toolbar: { hidden: true },
                            actionBar: { actions: ['today'] }
                        }}
                        timezone='UTC'
                        views={['year', 'month', 'day']}
                        onChange={(newValue) => {
                            if (newValue === null) {
                                return;
                            }
                            const value = newValue.utc().set('hour', 0).set('minute', 0).set('second', 0).unix().toString();
                            props.SetQueryValue(value, () => newValue.format(GetDateLocale()))
                        }}
                        value={value}
                        disableFuture
                    />
                </LocalizationProvider>;
        }
        return <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={ToLocals(CurrentLanguage.value)}>
            <StaticDateTimePicker
                timezone='UTC'
                views={['year', 'month', 'day']}

                onChange={(newValue) => {

                }}
                disableFuture
            />
        </LocalizationProvider>;
    }

    async GetDisplay(value: string) {
        const date = Dayjs.unix(parseInt(value));
        return () => {
            return date.format(GetDateLocale())
        }
    }

    OnInputBlur(value: string) {
        const date = Dayjs.utc(value, GetDateLocale(), true)
        if (!date.isValid()) {
            return null;
        }
        return {
            newValue: date.unix().toString(),
            display: () => {
                return date.format(GetDateLocale())
            }
        }
    }
}