import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import MenuIcon from '@mui/icons-material/Menu'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { ThemeProvider } from '@mui/material/styles'
import { useSignals } from '@preact/signals-react/runtime'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom'
import SideMenu, { IsSideMenuOpen } from './components/SideMenu'
import './index.css'
import { IsInitLogIn, LoggedIn } from './network/Account'
import LoginView from './page/LoginView'
import { MainContent } from './page/MainContent'
import { DarkTheme } from './Utils/theme'

function RequireAuth({ children }: { children: JSX.Element }) {
    const location = useLocation();
    if (!LoggedIn.value) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    } else {
        return children;
    }
}

function LoggedInRedirect() {
    const location = useLocation();
    if (LoggedIn.value) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }
    return <LoginView />;
}

export function App() {
    useSignals();

    return <ThemeProvider theme={DarkTheme}>
        <SnackbarProvider maxSnack={4} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} />
        <BrowserRouter>
            <Routes>
                {IsInitLogIn.value ? null : <>
                    <Route path="/login" Component={LoggedInRedirect} />
                    <Route path="/*" element={<RequireAuth>
                        <Box id='main' className={IsSideMenuOpen.value ? 'open' : undefined} sx={{
                            width: '100vw',
                            height: '100vh',
                            display: 'flex'
                        }}>
                            <Box sx={{ flexGrow: 1, overflow: 'hidden', display: 'flex' }}>
                                <MainContent />
                            </Box>
                            <Box id="sideMenuMask" onClick={() => IsSideMenuOpen.value = false}></Box>
                            <Box id="sideMenuToggle" sx={{
                                background: theme => theme.palette.secondary.main,
                                '&::before': {
                                    boxShadow: theme => `-8px 8px 0 ${theme.palette.secondary.main}`
                                },
                                '&::after': {
                                    boxShadow: theme => `-8px -8px 0 ${theme.palette.secondary.main}`
                                }
                            }}>
                                <IconButton size='large' onClick={() => IsSideMenuOpen.value = true}><MenuIcon /></IconButton>
                            </Box>
                            <SideMenu />
                        </Box>
                    </RequireAuth>}></Route>
                </>}
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
)
