import { signal } from "@preact/signals-react";
import { i18n, i18nSheet } from "./generated/i18n";

const LocalStorageKey_Language = 'language';

export enum Language {
    en = 'en',
    zh_TW = 'zh_TW'
}

export const BrowserLanguageMap: Map<Language, string> = new Map([
    [Language.en, 'en-US'],
    [Language.zh_TW, 'zh-TW']
]);

const DateLocaleMap: Map<Language, string> = new Map([
    [Language.en, 'MM/DD/YYYY'],
    [Language.zh_TW, 'YYYY/MM/DD']
]);

let storedLanguage = window.localStorage.getItem(LocalStorageKey_Language);
if (!Object.values(Language).includes(storedLanguage as Language)) {
    storedLanguage = Language.en;
    window.localStorage.setItem(LocalStorageKey_Language, storedLanguage);
}

export const CurrentLanguage = signal(storedLanguage as Language);

export function SetLanguage(lang: Language) {
    if (!Object.values(Language).includes(storedLanguage as Language)) {
        lang = Language.en;
    }
    window.localStorage.setItem(LocalStorageKey_Language, lang);
    CurrentLanguage.value = lang;
}

export function GetText(key: i18n | string, ...args: (string | number)[]): string {
    let i18nData: i18n;
    if (typeof key === 'string') {
        const data = i18nSheet.DataMap.get(key);
        if (!data) {
            return key;
        }
        i18nData = data;
    } else {
        i18nData = key;
    }

    if (Array.isArray(args[0])) {
        args = args[0];
    }

    if (args.length > 0) {
        return i18nData[CurrentLanguage.value].replace(/{(\d+)}/g, (match, number) => {
            return args[number] !== undefined ? args[number].toString() : match;
        });
    }
    return i18nData[CurrentLanguage.value];
}

export function GetDateLocale() {
    return DateLocaleMap.get(CurrentLanguage.value) ?? 'MM/DD/YYYY';
}

export function ToLocals(lang: Language) {
    return lang.toLowerCase().replace('_', '-');
}