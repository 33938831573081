import type { SvgIconComponent } from "@mui/icons-material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { i18n, i18nSheet } from "../dataSheets/generated/i18n";
import { GetText } from "../dataSheets/i18n";
import { DataViewEditorControl, DataViewEditorSize, DataViewRowControl } from "../model/DataView";

export function ViewButton(props: { data: any, controls: DataViewEditorControl }) {
    return <IconButton size="small" onClick={() => { props.controls.OpenView(props.data) }}><Tooltip title={GetText(i18nSheet.DataView_Detail)} arrow disableInteractive placement="top"><PreviewIcon fontSize="small" /></Tooltip></IconButton>
}

export function EditButton(props: { data: any, controls: DataViewEditorControl }) {
    return <IconButton size="small" onClick={() => { props.controls.OpenEdit(props.data) }}><Tooltip title={GetText(i18nSheet.DataView_Edit)} arrow disableInteractive placement="top"><EditIcon fontSize="small" /></Tooltip></IconButton>
}

export function DeleteButton(props: { data: any, controls: DataViewEditorControl }) {
    return <IconButton size="small" onClick={() => { props.controls.OpenDelete(props.data) }}><Tooltip title={GetText(i18nSheet.DataView_Delete)} arrow disableInteractive placement="top"><DeleteIcon fontSize="small" /></Tooltip></IconButton>
}

export function CustomButton(props: { tooltip: i18n | i18n[], icon: SvgIconComponent, data: any, controls: DataViewEditorControl, renderer: (data: any, controls: DataViewEditorControl, click: any) => JSX.Element, size?: DataViewEditorSize }) {
    const title = Array.isArray(props.tooltip) ? GetText(props.tooltip[0], ...props.tooltip.slice(1).map(i18n => GetText(i18n))) : GetText(props.tooltip)
    return <IconButton size="small" onClick={() => { props.controls.OpenCustom(props.data, props.renderer, props.size) }}><Tooltip title={GetText(title)} arrow disableInteractive placement="top"><props.icon fontSize="small" /></Tooltip></IconButton>
}

export function SaveButton(props: { controls: DataViewRowControl, row: any, disabled?: boolean }) {
    return <IconButton disabled={props.disabled || props.controls.IsLoading} size="small" onClick={async () => { await props.controls.Save(props.row) }}><Tooltip title={GetText(i18nSheet.DataView_Save)} arrow disableInteractive placement="top"><SaveIcon fontSize="small" /></Tooltip></IconButton>
}