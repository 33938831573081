import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSignals } from "@preact/signals-react/runtime";
import DataView from "../../components/DataView/DataView";
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";
import { DataViewColumn } from "../../model/DataView";
import { Get } from "../../Utils/net";

enum AuditAction {
    Add = 1,
    Edit = 2,
    Delete = 3
}

export default function Audit() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.Audit_AdminName,
            Field: 'Admin',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.Audit_Action,
            Field: 'Action',
            TableRenderer: Action_TableRenderer
        },
        {
            Name: i18nSheet.Audit_Table,
            Field: 'Table',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.Audit_Content,
            Field: 'Change',
            TableRenderer: Content_TableRenderer
        },
        {
            Name: i18nSheet.Audit_Time,
            Field: 'CreatedAt',
            TableRenderer: (data, column) => <>{new Date(data[column.Field]).toLocaleString()}</>
        }
    ]

    return <DataView
        Queries={[]}
        QueryFunction={async (queries, page) => {
            const req: Record<string, string> = { ...page }
            return await Get("/api/audit/get", req);
        }}
        AutoLoading
        Columns={columns}
    ></DataView>
}

function Action_TableRenderer(data: any, column: DataViewColumn) {
    let text = i18nSheet.Error_Unknown;
    let color: any = 'default';

    switch (data[column.Field]) {
        case AuditAction.Add:
            text = i18nSheet.DataView_Add;
            color = 'info';
            break;
        case AuditAction.Edit:
            text = i18nSheet.DataView_Edit;
            color = 'warning';
            break;
        case AuditAction.Delete:
            text = i18nSheet.DataView_Delete;
            color = 'error';
            break;
    }
    return <Chip color={color} label={GetText(text)} />
}

function Content_TableRenderer(data: any, column: DataViewColumn) {
    if (!data[column.Field]) {
        return <></>
    }
    if (data.Action === AuditAction.Edit) {
        const header = Object.entries(data[column.Field]).filter(([key, value]) => !Array.isArray(value));
        const body = Object.entries(data[column.Field]).filter(([key, value]) => Array.isArray(value));
        return <Table size="small">
            <TableHead>
                {header.map(([key, value]: any) => <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell colSpan={2}>{value}</TableCell>
                </TableRow>)}
            </TableHead>
            <TableBody>
                {body.map(([key, value]: any) => <TableRow>
                    <TableCell>{key}</TableCell>
                    <TableCell>{value[0] === null ? '-' : JSON.stringify(value[0], null, 4)}</TableCell>
                    <TableCell>{value[1] === null ? '-' : JSON.stringify(value[1], null, 4)}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    }
    return <Table size="small">
        <TableBody>
            {Object.entries(data[column.Field]).map(([key, value]: any) => <TableRow>
                <TableCell>{key}</TableCell>
                <TableCell>{value === null ? '-' : JSON.stringify(value, null, 4)}</TableCell>
            </TableRow>)}
        </TableBody>
    </Table>
}