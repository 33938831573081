import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSignals } from "@preact/signals-react/runtime";
import dayjs from "dayjs";
import DataView from "../../components/DataView/DataView";
import DateTimeQuery, { DateTimeQueryType } from "../../components/DataView/Query/DateTimeQuery";
import EnumQuery from "../../components/DataView/Query/EnumQuery";
import StringQuery from "../../components/DataView/Query/StringQuery";
import { ViewButton } from "../../components/IconButton";
import { i18nSheet } from "../../dataSheets/generated/i18n";
import { GetText } from "../../dataSheets/i18n";
import { DataViewColumn } from "../../model/DataView";
import { User } from "../../network/Account";
import { GetGameIds } from "../../network/Game";
import { GetOperators } from "../../network/Operator";
import { Get } from "../../Utils/net";

export default function BetRecord() {
    useSignals();
    const columns: DataViewColumn[] = [
        {
            Name: i18nSheet.BetRecord_TransId,
            Field: 'TransId',
            TableRenderer: (data, column) => <Box sx={{
                position: 'relative',
                maxWidth: 100,
                textWrap: 'nowrap',
                overflow: 'visible',
                px: 1,
                py: 0.5,
                '& .tooltip': {
                    content: `"${data[column.Field]}"`,
                    backgroundColor: (theme) => theme.palette.secondary.dark,
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    opacity: 0,
                    px: 1,
                    py: 0.5,
                    maxWidth: 'auto',
                    borderRadius: 5,
                    transition: 'opacity 0.25s',
                },
                '&:hover .tooltip': {
                    opacity: 1,
                }
            }}>
                <Box sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}>
                    {data[column.Field]}
                </Box>
                <Box className="tooltip">{data[column.Field]}</Box>
            </Box>
        },
        {
            Name: i18nSheet.Operator_Name,
            Field: 'Operator',
            TableRenderer: User.value?.IsSiteOperator ? (data, column) => <>{data[column.Field]?.Name}</> : undefined
        },
        {
            Name: i18nSheet.BetRecord_PlayerName,
            Field: 'Player',
            TableRenderer: (data, column) => <>{data[column.Field]?.PlayerAccount}</>
        },
        {
            Name: i18nSheet.Game_Name,
            Field: 'GameId',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.BetRecord_BetAmount,
            Field: 'BetAmount',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.BetRecord_WinAmount,
            Field: 'WinAmount',
            TableRenderer: (data, column) => <>{data[column.Field]}</>
        },
        {
            Name: i18nSheet.BetRecord_BetTime,
            Field: 'CreatedAt',
            TableRenderer: (data, column) => <>{new Date(Number(data[column.Field])).toUTCString()}</>
        },
        {
            Name: i18nSheet.DataView_Action,
            Field: '',
            TableRenderer: (data, col, control) => {
                return <>
                    <ViewButton controls={control.Editor} data={data} />
                </>
            },
            ViewRenderer: {
                RenderView(data, column, controls, forceUpdate) {
                    return <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{GetText(i18nSheet.BetRecord_GameType)}</TableCell>
                                <TableCell>{GetText(i18nSheet.BetRecord_Count)}</TableCell>
                                <TableCell>{GetText(i18nSheet.BetRecord_WinAmount)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Free</TableCell>
                                <TableCell>{data.FreeGameCount}</TableCell>
                                <TableCell>{data.FreeGameWinAmount}</TableCell>
                            </TableRow>
                        </TableBody>
                        <TableBody>
                            <TableRow>
                                <TableCell>Bonus</TableCell>
                                <TableCell>{data.BonusGameCount}</TableCell>
                                <TableCell>{data.BonusGameWinAmount}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                },
            }
        }
    ]

    return <DataView
        Queries={[
            new EnumQuery({ Label: i18nSheet.Account_Operator, Field: 'OperatorId', EnumValues: GetOperators, Disabled: !User.value?.IsSiteOperator }),
            new StringQuery({ Label: i18nSheet.BetRecord_PlayerName, Field: 'UserId' }),
            new EnumQuery({
                Label: i18nSheet.Game_Name, Field: 'GameId', EnumValues: async () => {
                    const res = await GetGameIds();
                    return res.map((x) => [x, x]);
                }
            }),
            new DateTimeQuery({ Label: i18nSheet.DataView_StartTime, Field: 'StartTime', Type: DateTimeQueryType.Date }),
            new DateTimeQuery({ Label: i18nSheet.DataView_EndTime, Field: 'EndTime', Type: DateTimeQueryType.Date }),
        ]}
        QueryFunction={async (queries, page) => {
            const req: Record<string, string> = { ...page }
            if (queries.OperatorId) {
                req.OperatorId = queries.OperatorId;
            }
            if (queries.UserId) {
                req.UserId = queries.UserId;
            }
            if (queries.GameId) {
                req.GameId = queries.GameId;
            }
            if (queries.StartTime) {
                const startTime = parseInt(queries.StartTime);
                if (!isNaN(startTime)) {
                    req.StartTime = startTime.toString();
                }
            }
            if (queries.EndTime) {
                const endTime = parseInt(queries.EndTime);
                if (!isNaN(endTime)) {
                    let time = dayjs.unix(endTime);
                    time = time.add(1, 'day');
                    req.EndTime = time.unix().toString();
                }
            }
            return await Get("/api/bet/get", req);
        }}
        Columns={columns}
    ></DataView>
}